// vim: et sw=2 ts=2
(function($, G) {

  $.cf.installer('time', function($el) {
    var $clone = $el.clone();
    $el.addClass('not-palm-landscape-and-under');
    $clone.addClass('not-lap-and-up');
    var dt = $el.attr('datetime');
    $clone.html(dt.substring(8, 10) + '/' + dt.substring(5, 7) + '/' + dt.substring(0, 4));
    $el.after($clone);
  });

  $.cf.installer('.comment-form', function($form) {
    var $btn = $form.find('.btn-submit');
    $form.on('submit', function() {$btn.prop('disabled', true)});
  });

})(window.jQuery, window);

