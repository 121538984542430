(function($){

/*
 The following code could prove to be useful for future updates.
 Especially for mobile views since "drag down to refresh" is present on a lot of mobile browsers.
 However more work is needed to make it work fluidly.
 Probably should wait untill Fullscreen API is supportted fully cross-browser.
*/

	function LaunchFullscreen(element) {
		if(element.requestFullscreen) {
			element.requestFullscreen();
		} else if(element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if(element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		} else if(element.msRequestFullscreen) {
			element.msRequestFullscreen();
		}
	}
	function ExitFullscreen() {
		if(document.exitFullscreen) {
			document.exitFullscreen();
		} else if(document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if(document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	}


	var currentScroll;

  function goFullscreen($el) {

		console.log('goFullscreen', $el);
    // As of jan'16 mindmaps render too slowly in fullscreen mode in smaller devices.
    // So, instead of launching the popover containing the mindmap, open a pdf of the mindmap in a new tab.
    // The mindmap fullscreen functionality has not been removed, and is working.
    // Removing this condition will make mindmaps open in a normal popover again.
    if ($('.resource-view--mindmap').length && ($.cookies.get('viewport_width') !== 'desk')) {
      window.open(window.location.href + '/pdf');
      return;
    }

    if (!$el.find('.cards-set').hasClass('is-free-preview') && !$.cf.access.can('view', $el.closest('.resource-view'))) {
      return $.cf.access.resolve($el);
    }

    if ($('.resource-view--image').length) {
      window.open($('.resource-view--image .download .btn--cta').attr('href'));
      return;
    }

		if ($(document.body).hasClass('js-is-fullscreen')) return;
		$(document.body).addClass('js-is-fullscreen');

    var modalContent = $el.children().clone();

    // For diagrams, content is newly generated on popover launch, so need to remove the cloned diagram from the main page
    // to avoid there being a duplicate diagram in the popover.
    if ($el.find('.diagram').length) {
      modalContent.find('.dia-scrollbox').children().remove();
      modalContent.find('.zoom').remove();
    }

    //			LaunchFullscreen(document);
    if ($(window).outerWidth() < 729) {
      $('.modal-backdrop').css({
        'opacity': 1,
        'background-color':'white',
      });
    }
    currentScroll = $("body").scrollTop();
		var subtype = $('#page').data('page_subtype');
    var $wrapper = $('<div class="fullscreen__wrapper fullscreen__wrapper--' + subtype + '"></div>')
    var $modal_fullscreen = $('<div class="modal-fullscreen"></div>');
    var $content_container = $('<div class="fullscreen__content-container"></div>');
    $wrapper.append($modal_fullscreen);
    $modal_fullscreen.append($content_container);
    $wrapper.append('<div class="modal-backdrop fade in "></div>');
    $('body').append($wrapper);
    $content_container.append(modalContent.clone());
    $content_container.append('<span class="btn btn--fullscreen--close"><span class="hide">Close</span></span>');
    $modal_fullscreen.append('<span class="rotate"><strong>rotate</strong> to view</span>');
    $wrapper.cf_install();
    if($modal_fullscreen.find('.notes-resource').length){
      $content_container.addClass('notes-no-rotate');
    }
		$content_container.find('.btn').not('.btn-next, .btn-previous, .btn-flip, .btn--fullscreen--close, .in, .out').remove();

    $('html,body').animate({ scrollTop: 0 }, 100);
    setTimeout(function(){
      $('html,body').css({'overflow':'hidden', 'height': '100%', 'width':'100%'});
      $(window).trigger('resize');
    }, 90);
    $content_container.hide().fadeIn(200).css('display', '');

    return $wrapper;
  }

	$.cf.installer('.js-resource-fullscreen', function($el){

		$el.find('.btn--fullscreen').on('click', function(){
			goFullscreen($el);
		});

  });

	$.cf.installer('.resource-view--cardset, .resource-view--notes', function($el){
		$el.on('click', '.btn--test-yourself', function(){
      $.ajax({
        url: document.location.pathname + '/test?r=1',
        success: function(response) {
          goFullscreen($(response)).addClass('test-yourself');
        },
        error: function(response) {
          console.error(response)
        }
      })
		});

	});

	$.cf.installer('.modal-fullscreen', function($el){
		$el.find('.btn--fullscreen--close').click(function(){
//			ExitFullscreen();
			$('html,body')
			.delay('slow')
			.css({'overflow':'', 'height': '', 'width':''})
			.scrollTop( currentScroll );

			$('.fullscreen__wrapper').fadeOut(200, function(){
				$(this).remove();
				$('.js-is-fullscreen').removeClass('js-is-fullscreen');
			});
		});

		$el.find('.rotate').click(function(){
			$(this).fadeOut(200);
		});

		$(window).on('resize', function(){
      if (!cfui.mediaQuery('desk-and-up')) {

				var orientation = $(window).outerHeight() > $(window).outerWidth() ? 'portrait' : 'landscape';

				$('.fullscreen__wrapper').addClass('fullscreen--'+orientation);
				if (orientation === 'portrait' && !($el.find('.notes-resource').length)){
					$('.fullscreen__wrapper').removeClass('fullscreen--landscape');
					$el.parent().find('.modal-backdrop').css('display', 'none');
					$el.find('.fullscreen__content-container').height($(window).outerWidth());
					$el.find('.fullscreen__content-container').width($(window).outerHeight());
					var negMargin = ($(window).height() - $(window).width())/2;
					$el.find('.fullscreen__content-container').css({
						'margin-top': negMargin+'px',
						'margin-left': '-'+negMargin+'px',
					});
					$el.find('.rotate').show();
				}else{
					$('.fullscreen__wrapper').removeClass('fullscreen--portrait');
					var $content_container = $el.find('.fullscreen__content-container');
					$content_container.removeAttr('style');
					$el.find('.rotate').hide();

				}
				setTimeout(function(){
					$el.find('.slides__viewport').trigger('updateCards');
				}, 100);
			}else {
				$el.find('.fullscreen__content-container').removeAttr('style');
			}

      resizeNotes();
		});

    function resizeNotes() {
      var $grNotesTestContent = $el.find('.grNotes .grPhraseGame__content');
      if ($grNotesTestContent.length) {
        var $content_container = $grNotesTestContent.parents('.fullscreen__content-container').first();
        var pos = $grNotesTestContent.position();
        if ($grNotesTestContent.innerHeight() != $content_container.height() - pos.top) {
          $grNotesTestContent.css('height', $content_container.height() - pos.top);
        }
      }
    }

    resizeNotes();

    if ($el.find('.diagram').length) {
      // If a viewport that contains a diagram in a popover is resized, change the height of the canvas in which the diagram is rendered.
      // The height of the popover is changed automatically, the height of the canvas must be changed manually.
      // Mostly useful for orientation changes.
      $(window).on('resize', function() {
        // Set the height of the canvas as a fraction of the popover height.
        // Smaller fraction needed in portrait mode due to the popover potentially overflowing the viewport.
        var multiplier = (window.innerHeight > window.innerWidth) ? 0.95 : 1;
        $('.modal-fullscreen .dia-scrollbox').height($('.fullscreen__content-container').height() * multiplier);
      });
    }

	});

})(jQuery);
