// vim: et sw=2 ts=2
(function($, cfui) {

  var hideHamburger = function() {
    $('.nav-main').removeClass('nav-main--selected');
    $('.nav-main__item--hover').removeClass('nav-main__item--hover');
    $('.nav-main__wrapper').removeClass('nav-main__wrapper--active')
    .removeClass('nav-main__wrapper--focused');
  };

  var showHamburger = function() {
    $('.nav-main').addClass('nav-main--selected');
    $('.nav-main__wrapper').addClass('nav-main__wrapper--active');
  };

  var hideUserMenu = function() {
    $('.page-header__user-menu')
      .removeClass('page-header__user-menu--active')
      .removeClass('page-header__user-menu--focused')
    ;
  };

  var hideSearchMenu = function() {
    $('.page-header__search')
      .removeClass('page-header__search--active')
      .removeClass('page-header__search--focused')
      .find('[type=text]').first().blur();
    ;
  };

  var toggleHamburger = function() {
    $('.nav-main').toggleClass('nav-main--selected');
    $('.nav-main__wrapper').toggleClass('nav-main__wrapper--active');
    $('.nav-main__wrapper').toggleClass('nav-main__wrapper--focused');
    hideSearchMenu();
    hideUserMenu();
  };

  /*
   * Header search box dropdown
   */
  $.cf.installer('.page-header__search', function($root) {

    $(document.body).on('touchstart', function(e) {
      if (! $(e.target).parents('.page-header').length) {
        hideHamburger();
        hideSearchMenu();
        hideUserMenu();
      }
    });

    $root.hoverIntent(function() {
      $root.addClass('page-header__search--active');
      hideUserMenu();
      hideHamburger();
    }, function() {
      $root.removeClass('page-header__search--active');
    });

    $root.on('touchend', function(e) {
      if ($root.is(e.target)) {
        $root.off('.hoverIntent');
        if ($root.hasClass('page-header__search--active')) {
          hideSearchMenu();
        }
        else {
          $root.addClass('page-header__search--active');
          hideUserMenu();
          hideHamburger();
        }
        return false;
      }
    });

    $root.find('[type=text]').on({
      focus: function(e) {
        $root.addClass('page-header__search--focused');
      },
      blur: function(e) {
        $root.removeClass('page-header__search--focused');
      }
    });

    $root.find('select').on({
      'click': function(e) {
        $root.addClass('page-header__search--focused');
      }
    });

    $root.on({
      'change': function(e) {
        if (e.target.tagName === 'SELECT') {
          $root.find('[type=text]').first().focus();
        }
      }
    });

    // update resource type dropdown when breakpoint changes to remove
    // types that aren't available on small and medium.
    var $filter_kind = $root.find('select[name=filter_kind]'),
      $field = $filter_kind.parent()
    ;
    $filter_kind = $filter_kind.clone();

    var updateKinds = function(e) {
      var $el = $filter_kind.clone();
      if ( ! cfui.mediaQuery('desk-and-up')) {
        $el.find('option').filter(function() {
          return this.value && -1 === window.gr_mobile_resource_kinds.indexOf(this.value)
        }).remove();
      }
      $field.children().remove();
      $field.append($el);
    };

    $(document).on('breakpointchange', updateKinds);
    updateKinds();

  });


  $.cf.installer('.page-header__user-menu__join', function($root) {

    var $menu = $root.parents('.page-header__user-menu');
    var $join_submenu = $root.find('.page-header__user-menu__join-menu');

    if (cfui.mediaQuery('desk-and-up')) {

      /*
       * Header signin dropdown on large screens from login button.
       */
      var $login_link = $root.parent().find('.page-header__user-menu__login-link');

      $join_submenu.appendTo($login_link.parent());
      $join_submenu.removeClass('not-desk-and-up');
      $join_submenu.find('.signin-menu__login h2').text('Log in to your account');

      $login_link.on('click', function(e) {
        if ($join_submenu.css('display') !== 'none') {
          $('.page-header__search').find('[type=text]').first().blur();
          $menu.toggleClass('page-header__user-menu--focused');
          $menu.toggleClass('page-header__user-menu--active');
          return false;
        }
      });

      $login_link.hoverIntent(function() {
        $('.page-header__search').find('[type=text]').first().blur();
        $menu.addClass('page-header__user-menu--active');
        hideSearchMenu();
        hideHamburger();
      }, function() {
        $menu.removeClass('page-header__user-menu--active');
      });

    }
    else {

      /*
       * Header signin dropdown on small and medium screens from join button.
       */

      $root.find('.page-header__user-menu__join-link').on('click touchend', function(e) {
        if (e.type === 'touchend') {
          $root.off('.hoverIntent');
        }
        if ($menu.hasClass('page-header__user-menu--active')) {
          hideUserMenu();
        }
        else {
          hideHamburger();
          hideSearchMenu();
          $('.page-header__search').find('[type=text]').first().blur();
          $menu.toggleClass('page-header__user-menu--focused');
          $menu.toggleClass('page-header__user-menu--active');
        }
        return false;
      });

      $root.hoverIntent(function() {
        $('.page-header').find(':focus').first().blur();
        $menu.addClass('page-header__user-menu--active');
        hideHamburger();
        hideSearchMenu();
      }, function() {
        $('.page-header').find(':focus').first().blur();
        $menu.removeClass('page-header__user-menu--active');
      });

    }

  });


  $.cf.installer('.page-header__user-menu--member', function($root) {

    $root.hoverIntent(function(e) {
      hideHamburger();
      hideSearchMenu();
      if (cfui.mediaQuery('lap-and-up')) {
        $root.addClass('page-header__user-menu--active');
      }
    }, function() {
      $root.removeClass('page-header__user-menu--active');
    });

    $root.on('click touchend', function(e) {
      console.log(e.target);
      if ($root.is(e.target)) {
        if (e.type !== 'touchend' && $root.hasClass('page-header__user-menu--active')) {
          $root.removeClass('page-header__user-menu--active');
        }
        else {
          hideHamburger();
          hideSearchMenu();
          $root.addClass('page-header__user-menu--active');
        }
        return false;
      }
      else if ( ! $root.hasClass('page-header__user-menu--active')) {
        e.preventDefault();
      }
    });

  });



  /*
   * Main navigation menu
   */
  $.cf.installer('.nav-main', function($root) {

    var menu_data = window.gr_navigation;

      var generateMenuItems = function(link_group, level, itemCount, renderTopics) {

        var level_data = menu_data[link_group][level];

        var $mainMenuItem = $('.nav-item--' + level);

        var $menuItemContainer = $('<div></div>', {class: 'nav-main__subject-container'})
        .append('<h5 class="nav-main__subject-heading not-lap-and-under">' + level_data.title + '</h5>' +
        '<div class="nav-main__full-subjects not-lap-and-under">' +
          '<h5 class="nav-main__full-subjects__heading">Your subject not listed?</h5>' +
          '<p class="nav-main__full-subjects__text">Have a look at our <a href="/resources/level/' + level + '/subjects">full subject list</a></p>' +
        '</div>');

        var $subjectItems = $('<ul></ul>', {class: 'nav-main__subjects'});

        for (var i = 0, len = Math.min(itemCount, level_data.subjects.length); i < len; i++) {

          var currentSubject = level_data.subjects[i];

          var $subjectItem = $('<li></li>', {class: 'nav-main__subject'})
          .append('<a href="' + currentSubject.url + '" class="nav-main__subject-link">' + currentSubject.label + (!renderTopics ? ' (' + currentSubject.count + ')' : '') + '</a>');

          if (renderTopics && currentSubject.topics && currentSubject.topics.length) {

            var $topicItems = $('<ul></ul>', {class: 'nav-main__topics'});

            for (var j = 0, topics_len = Math.min(itemCount, currentSubject.topics.length); j < topics_len; j++) {
              var currentTopic = currentSubject.topics[j];
              $topicItems.append($('<li><a href="' + (currentSubject.url + '&topic[]=' + escape(currentTopic)) + '" class="nav-main__topic-link">' + currentTopic + '</a></li>'));
            }
            $topicItems.append('<li><a href="' + currentSubject.url + '" class="nav-main__topic-link nav-main__topic-link--all">All ' + currentSubject.label + ' topics »</a></li>').appendTo($subjectItem).hide();
          }
          $subjectItems.append($subjectItem);
        }
        if (renderTopics && currentSubject) {
          $subjectItems.append(
            '<li class="nav-main__subject">' +
            '<a href="/resources/level/' + level + '/subjects" class="nav-main__subject-link nav-main__subject-link--all">All ' + $mainMenuItem.find('.nav-item__link span').text() + ' subjects »</a>' +
            '</li>'
          );
        }
        $mainMenuItem.append($menuItemContainer.append($subjectItems));

      };


    // itemCount is the number of items to render in the menu, renderTopics is whether or not topics should be rendered.
    // The subject level names should match those found in the css and also the json file.
    var generateAllMenuItems = function(link_group, itemCount, renderTopics) {
      generateMenuItems(link_group, 'gcse', itemCount, renderTopics);
      generateMenuItems(link_group, 'a_ib', itemCount, renderTopics);
      generateMenuItems(link_group, 'uni', itemCount, renderTopics);
    };


    // >> Start event handlers that are added to elements in certain size menus.
    var generatePalmMenu = function() {
        // Invoked when a subject level is clicked, eg 'gcse' or 'a-level'.

        // Select any subject lists that are currently visible, before the click happened.
        var $visibleSubjectLists = $('.nav-main__subjects:visible');

        // Hide any visible topic lists. All topics are hidden when any subject level is toggled, including within the clicked subject level.
        $visibleSubjectLists.find('.nav-main__topics:visible').slideToggle();

        // Also de-select any selected subject items within all subject levels.
        $visibleSubjectLists.find('.nav-main__subject-link--selected').removeClass('nav-main__subject-link--selected');

        // If the clicked subject level wasn't already open, close any other open subject level.
        if ($(this).parent().find('.nav-main__subjects').is(':hidden')) {
          $visibleSubjectLists.slideToggle();
        }

        // Toggle the subject list within the clicked subject level.
        $(this).parent().find('.nav-main__subjects').slideToggle();
    };


    var nofollow = function() {
      return false;
    };
    // >> End event handlers that are added to elements in certain size menus.


    // Resets the DOM to its state before any menu was generated.
    var removeMenu = function() {
      // Destroy the existing javascript-generated menu DOM objects.
      $('.nav-main__subject-container').remove();

      // Reset the DOM objects defined in the twig template to their original state.
      $('.nav-main__hamburger').unbind('click', toggleHamburger);
      $('.nav-main').off('.hoverIntent');
      $('.nav-item__link').unbind('click', generatePalmMenu);
      $('.nav-item--gcse .nav-item__link, .nav-item--a_ib .nav-item__link, .nav-item--uni .nav-item__link').unbind('click', nofollow);
      $('.nav-main').removeClass('nav-main--selected');
      $('.nav-main__list').addClass('nav-main__list--hide').addClass('pack').addClass('pack--auto').addClass('pack--middle');
      $('.nav-main__item').addClass('pack__item');

      $('.nav-main .nav-main__item').off('mouseenter mouseleave');
      $('.nav-main .nav-item__link').off('.lap-and-up');
    };


    var windowWidth = $(window).width();

    var createMenu = function() {

      windowWidth = $(window).width();

      if (!cfui.mediaQuery('lap-and-up')) {
        $('.nav-main__list').removeClass('pack').removeClass('pack--auto').removeClass('pack--middle');
        $('.nav-main__item').removeClass('pack__item');
      }

      function setupHoverEvents() {
        $('.nav-main .nav-main__item').on('hover', function() {
          $(this).addClass('nav-main__item--hover');
        }, function() {
          $(this).removeClass('nav-main__item--hover');
        });

        var isTablet = false;

        $('.nav-main .nav-item__link').on('touchstart.lap-and-up', function(e) {
          $('.nav-main .nav-main__item').off('mouseenter mouseleave');
          $('.nav-main .nav-item__link').off('touchstart');
          isTablet = true;
        });

        $('.nav-main .nav-item__link').on('click.lap-and-up', function(e) {
          if (isTablet) {
            if ($(this).parent().find('.nav-main__subject-container').length) {
              var $item = $(this).closest('.nav-main__item');
              $item.toggleClass('nav-main__item--hover');
              $('.nav-main__item--hover').not($item).removeClass('nav-main__item--hover');
              e.preventDefault();
              return false;
            }
          }
        });
      }

      if (cfui.mediaQuery('desk-and-up')) {
        generateAllMenuItems('desk', 21);
        setupHoverEvents();
      }
      else if (cfui.mediaQuery('lap-only')) {
        generateAllMenuItems('mobile', 10, true);
        setupHoverEvents();
      }
      else {
        generateAllMenuItems('mobile', 10, true);

        $('.nav-main__hamburger').on('click touchend', function(e){
          if ($('.nav-main__wrapper').hasClass('nav-main__wrapper--active')) {
            hideHamburger();
          }
          else {
            hideSearchMenu();
            hideUserMenu();
            $('.nav-main__wrapper').toggleClass('nav-main__wrapper--focused');
            $('.nav-main__wrapper').toggleClass('nav-main__wrapper--active');
          }
          return false;
        });

        $('.nav-main').hoverIntent(function() {
          hideSearchMenu();
          hideUserMenu();
          showHamburger();
        }, function() {
          $('.nav-main__wrapper').removeClass('nav-main__wrapper--active');
          $('.nav-main').removeClass('nav-main--selected');
        });

        // Remove the link from the main menu items, which now lead to a 'subjects' dropdown menu.
        $('.nav-item--gcse .nav-item__link, .nav-item--a_ib .nav-item__link, .nav-item--uni .nav-item__link').bind('click', nofollow);

        $('.nav-main__subjects').hide();

        $('.nav-item__link').bind('click', generatePalmMenu);
      }

      if (cfui.mediaQuery('lap-and-under')) {

        $('.nav-main__subject-link').on('click', function(e) {

          var $this = $(this);

          if ($this.hasClass('nav-main__subject-link--all') || 0 === $this.next('.nav-main__topics').length) {
            return;
          }

          {
            e.preventDefault();

            var $siblingSubjectItems = $(this).parent().siblings();

            // Hide the visible topic list and remove the 'selected' class from other open subjects.
            $siblingSubjectItems.find('.nav-main__topics:visible').slideToggle();
            $siblingSubjectItems.find('.nav-main__subject-link--selected').removeClass('nav-main__subject-link--selected');

            // Toggle the topic list and 'selected' class for the clicked subject item.
            $(this).siblings('.nav-main__topics').slideToggle();
            $(this).toggleClass('nav-main__subject-link--selected');

            return false;
          }

        });
      }

    };

    // Initialise menu on page load.
    createMenu();

    // When the viewport orientation (ie dimensions) is changed, generate a new menu matching the new viewport size.
    $(window).on('resize orientationchange', function(e) {
      // resize is also triggered on scroll (due to scrollbar appearance) so
      // check whether the width actually changed since last creating the menu.
      if (e.type !== 'resize' || $(window).width() !== windowWidth) {
        removeMenu();
        createMenu();
      }
    });

  });

  $.cf.installer('.dfp-ad-unit', function($ad) {
    var current = null;
    var dfp = $ad.data('dfp-units');
    var is_dev = /env-(local|dev)/.test(document.documentElement.className);

    function updateDFP() {
      if ( ! dfp) return;
      var unit;
      if (cfui.mediaQuery('standard-banner-ad-and-up')) {
        unit = dfp['desk'];
      }
      else if (cfui.mediaQuery('lap-and-up')) {
        unit = dfp['lap'];
      }
      else {
        unit = dfp['palm'];
      }

      if ( ! unit || unit.id === current) return;
      current = unit.id;
      $ad.children().hide();
      var $unit = $ad.find('#' + unit.id);
      if ($unit.length) {
        $unit.show();

        if (is_dev) {
          $unit.text('Ad unit ' + unit.id);
        }
        else {
          whenNearlyVisible(unit.id, function() {
            googletag.cmd.push(function() {
              googletag.display(unit.id);
            });
          });
        }

      }
      else {
        $unit = $("<div>", {'class': 'ad-unit__inner dfp-ad-unit__inner', id: unit.id});
        $ad.append($unit);
        $unit.css({
          'width': unit.size[0] + 'px',
          'height': unit.size[1] + 'px'
        });

        if (is_dev) {
          $unit.text('Ad unit ' + unit.id);
        }
        else {
          whenNearlyVisible(unit.id, function() {
            googletag.cmd.push(function() {
              googletag.defineSlot(unit.slot, unit.size, unit.id).addService(googletag.pubads());
              googletag.display(unit.id);
            });
          });
        }
      }

    }
    $(window).on('resize orientationchange', function(e) {
      updateDFP();
    });
    updateDFP();
  });

})(window.jQuery, window.cfui);
