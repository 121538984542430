// vim: et sw=2 ts=2
(function($, G) {

  G.cfui = G.cfui || {};

  function updateViewportCookie() {

    var viewport
      , current_viewport = $.cookies.get('viewport_width')
    ;

    if (G.cfui.mediaQuery('desk-and-up')) {
      viewport = 'desk';
    }
    else if (G.cfui.mediaQuery('lap-only')) {
      viewport = 'lap';
    }
    else if (G.cfui.mediaQuery('palm-landscape-only')) {
      viewport = 'palm-landscape';
    }
    else {
      viewport = 'palm-portrait';
    }

    $.cookies.set('viewport_width', viewport, {
      'hoursToLive': 24 * 365,
      'path': '/'
    });

    if (current_viewport !== viewport) {
      $(document).trigger('breakpointchange', [current_viewport, viewport]);
    }
  } 

  $(document).ready(updateViewportCookie);
  $(window).on('resize', updateViewportCookie);

})(jQuery, window);

