// vim: et sw=2 ts=2
(function($) {

  $.cf.installer('.results-list__again', function($el) {
      $el.on('click', function(e) {
        $('html, body').animate({scrollTop: 0}, 800);
        $('.results-filter__form').addClass('collapsable--in');
        // Remove the inline style attribute, which has 'max-height: 0px' added by jQuery when collapsible section is collapsed.
        $('.results-filter__form .collapsable__body').removeAttr('style');
      });
  });

})(window.jQuery);

