// vim: et sw=2 ts=2 ft=javascript
(function($) {

  var delay = 50;

  var is_clearing = false;

  var lang;

  window.displayDPC = function(profile, next) {
    if (typeof React === 'undefined' || ! profile.dpc) {
      if (next) {
        window.location = next
      }
      return;
    }

    $(document.documentElement).addClass('dpc-modal');
    var root = document.createElement('div');
    document.body.appendChild(root);
    var clearing = profile.dpc_clearing || /clearing/.test(window.location.search);
    var UniChoicesComponent = UniChoices;
    ReactDOM.render(<ModalRoot nextUrl={ next } render={ props => <UniChoicesComponent pages={clearing ? clearingPages : uniPages} close={ props.close } profile={ profile } dpc={ profile.dpc || {} } /> } />, root);
  }

  Array.prototype.findIndex = Array.prototype.findIndex || function(callback) {
    if (this === null) {
      throw new TypeError('Array.prototype.findIndex called on null or undefined');
    } else if (typeof callback !== 'function') {
      throw new TypeError('callback must be a function');
    }
    var list = Object(this);
    // Makes sures is always has an positive integer as length.
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    for (var i = 0; i < length; i++) {
      if ( callback.call(thisArg, list[i], i, list) ) {
        return i;
      }
    }
    return -1;
  };

  $(document).ready(function() {

    var profile = $('#page').data('profile');
    if (profile && profile.prompt_uni_courses) {
      window.displayDPC(profile);
    }

  });

  if (typeof React === 'undefined') {
    return;
  }

  var ClearingSteps = React.createClass({
    componentDidMount: function() {
      $.ajax({
        method: 'POST', url: '/profile/uni_courses_prompted',
        success: () => {}, error: console.error
      });
    },

    getInitialState: function() {
      return {clearing_terms: false, clearing_terms_error: null, email_address: '', mobile_no: ''};
    },

    componentWillMount: function() {
      this.setState({email_address: this.props.dpc.email_address || '', mobile_no: this.props.dpc.mobile_no || ''});
    },

    handleEmailAddress: function(e) {
      this.setState({email_address: e.target.value, email_error: null});
    },

    handleMobileNo: function(e) {
      this.setState({mobile_no: e.target.value});
    },

    handleTerms: function(e) {
      this.setState({clearing_terms: !!e.target.checked, clearing_terms_error: null});
    },

    handleSubmit: function() {
      if (this.state.clearing_terms) {

        if (this.state.saving) {
          return;
        }

        if (jQuery('#page.is-guest').length && ! this.state.email_address.length) {
          this.setState({email_error: 'Please enter your email address'});
          return;
        }

        var initial = {email_address: this.props.dpc.email_address || '', mobile_no: this.props.dpc.mobile_no || ''};
        var state = {email_address: this.state.email_address, mobile_no: this.state.mobile_no, clearing_terms: true};
        if (state.mobile_no !== initial.mobile_no || state.email_address !== initial.email_address) {
          this.setState({saving: true});
          $.ajax({
            method: 'POST', url: '/profile/dpc_contact_prefs', data: {email_address: state.email_address, mobile_no: state.mobile_no, clearing_terms: '1'},
            success: () => {
              this.setState({saving: false});
              this.props.onChangeState(state).then(() => this.props.onNext());
            }, error: (err) => {
              this.setState({saving: false});
              console.error(err);
            }
          });
        }
        else {
          this.props.onNext();
        }
      }
      else if (jQuery('#page.is-guest').length && ! this.state.email_address.length) {
        this.setState({email_error: 'Please enter your email address', clearing_terms_error: 'If you want to receive clearing comms, you must tick the box'});
      }
      else {
        this.setState({clearing_terms_error: 'If you want to receive clearing comms, you must tick the box'});
      }
    },

    render: function() {
      var year = (new Date).getFullYear();
      return (
        <UniChoicesPage title={ this.props.title }
          render={ () => (
            <div className="clearing-intro">
              <ul className="clearing-intro__steps">
                <li className="clearing-intro__step clearing-intro__step-1"><h2>Step 1</h2><p>{"Tell us what you hope to study in September " + year }</p></li>
                <li className="clearing-intro__step clearing-intro__step-2"><h2>Step 2</h2><p>We’ll send you supportive exam guidance and university alerts</p></li>
                <li className="clearing-intro__step clearing-intro__step-3"><h2>Step 3</h2><p>You won’t miss getting on the right course for you</p></li>
              </ul>
              <div className="clearing-intro__footer">
                { jQuery('#page.is-guest').length ? <div><h2>Email address</h2>
                <p className="clearing-contact-prefs__text-desc">Your email address will be used for exam support and Clearing opportunities</p>
                <div className="clearing-contact-prefs__email">
                  { this.state.email_error ? <p className="clearing-error">{ this.state.email_error }</p> : null }
                  <input aria-label="Your email address" type="text" value={ this.state.email_address } placeholder="Your email address" onChange={ e => this.handleEmailAddress(e) } />
                </div></div> : null }

                <h2>Mobile number</h2>
                <p className="clearing-contact-prefs__text-desc">Your mobile number will be used to send exam support and Clearing opportunities</p>
                <div className="clearing-contact-prefs__mobile">
                  <input aria-label="Your mobile number" type="text" value={ this.state.mobile_no } placeholder="Your phone number" onChange={ e => this.handleMobileNo(e) } />
                </div>

                { this.state.clearing_terms_error ? <p className="clearing-error">{ this.state.clearing_terms_error }</p> : null }
                <div className="clearing-intro__footer-terms">
                  <div className="clearing-intro__terms">
                    <input type="checkbox" id="clearing-intro__terms" checked={ this.state.clearing_terms } onChange={ e => this.handleTerms(e) } /><label htmlFor="clearing-intro__terms">I want to receive emails about clearing opportunities from universities and other organisations this summer. (Your data will be processed in accordance with the <a target="_blank" href="/pages/privacy">GR privacy notice</a>)</label>
                  </div>
                  <div className="clearing-intro__submit">
                    <button className="btn btn--primary" onClick={ () => this.handleSubmit() }>Submit</button>
                  </div>
                </div>
                <div className="clearing-intro__nope">
                  <span aria-role="button" className="clearing-intro__nope-text" onClick={ () => this.props.onClose() }>Not interested</span>
                </div>
              </div>
            </div>
          ) }
          />
      );
    }
  });

  var Thanks = React.createClass({
    render: function() {
      return (
        <UniChoicesPage title={ this.props.title }
          render={ () => (
            <div className="uni-thanks">
              <button className="btn btn--primary" onClick={ () => this.props.onClose() }>Close</button>
            </div>
          ) }
          renderFooter={ () => (
            <Pager onBack={ () => this.props.onBack() } />
          )}
          />
      );
    }
  });


  var CourseSelector = React.createClass({

    getInitialState: function() {
      return {courses: [], searchTerm: ''};
    },

    componentWillMount: function() {
      this.setState({courses: this.props.dpc.courses});
    },

    add: function(name) {
      if (this.state.courses.indexOf(name) === -1) {
        var courses = [].concat(this.state.courses);
        courses.push(name);
        courses.sort();
        this.setState({courses: courses, searchTerm: ''});
      }
    },

    remove: function(name) {
      var courses = [].concat(this.state.courses).filter(n => n !== name);
      this.setState({courses: courses});
    },

    toggle: function(name, enable) {
      if (enable) {
        this.add(name);
      }
      else {
        this.remove(name);
      }
    },

    onDontKnow: function() {
      var self = this;
      this.setState({courses: ["I don't know"]}, function() {
        self.handleNext();
      });
    },

    handleNext: function() {
      if (this.state.saving) {
        return;
      }
      var courses = [].concat(this.state.courses);
      if (courses.length) {
        this.setState({saving: true});
        $.ajax({
          method: 'POST', url: '/profile/uni_courses', data: {course: courses},
          success: () => {
            this.setState({saving: false});
            this.props.onChangeState({courses: courses}).then(() => this.props.onNext());
          }, error: (err) => {
            this.setState({saving: false});
            console.error(err);
          }
        });
      }
      else {
        this.props.onNext();
      }
    },

    render: function() {
      var courses = this.state.courses;
      var profile = this.props.profile;
      var options = profile.uni_courses;
      var dunnoCourse = "I'm open to studying any subject"
      return (
        <UniChoicesPage title={ this.props.title }
          render={ () => (
            <div>
            <p className="uni-choices__subtext">Keep your options open, <strong>choose more than one subject</strong> for more opportunities</p>
            <div className="uni-courses">
              <ul className="list-bare uni-courses__courses">
              { options.map((course, i) => (
                <li key={ course }><input type='checkbox' checked={ -1 < courses.indexOf(course) } id={'uc-course-' + i} onChange={ e => this.toggle(course, e.target.checked) } /><label htmlFor={'uc-course-'+i}>{ course }</label></li>
              )) }
                <li key={ dunnoCourse }><input type='checkbox' checked={ -1 < courses.indexOf(dunnoCourse) } id={'uc-course-dunno'} onChange={ e => this.toggle(dunnoCourse, e.target.checked) } /><label htmlFor={'uc-course-dunno'}>{ dunnoCourse }</label></li>
              </ul>
            </div>
            </div>
          ) }
          renderFooter={ () => (
            <Pager onBack={ () => this.props.onBack() } onNext={ () => this.handleNext() } saving={ this.state.saving }>
            </Pager>
          )}
          />
      )
    }
  });


  var UniList = React.createClass({

    render: function() {
      var checks = !!this.props.checks;
      var onChange = this.props.onChange;
      var onRemove = this.props.onRemove;
      var searchTerm = this.props.searchTerm;
      var selections = this.props.selections;
      var profile = this.props.profile;
      var universities = profile.universities;
      if ( ! checks) {
        universities = universities.filter(uni => selections.indexOf(uni) === -1);
      }
      if (searchTerm.length) {
        universities = universities.filter(uni => uni.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      }

      if (checks) {
        var toggle = function(uni, v) {
          if (v) onChange(uni)
          else onRemove(uni)
        }
        var dunnoUni = "I don't know where yet" ;
        return (
          <div className="uni-choices__checks">
          <ul className="list-bare uni-choices__check-list">
          { universities.map((uni, i) => (
            <li key={ uni }><input type='checkbox' checked={ -1 < selections.indexOf(uni) } id={'uni-' + i} onChange={ e => toggle(uni, e.target.checked) } /><label htmlFor={'uni-'+i}>{ uni }</label></li>
          )) }
            <li key={ dunnoUni }><input type='checkbox' checked={ -1 < selections.indexOf(dunnoUni) } id={'uni-dunno'} onChange={ e => toggle(dunnoUni, e.target.checked) } /><label htmlFor={'uni-dunno'}>{ dunnoUni }</label></li>
          </ul>
          </div>
        )
      }

      return (
        <ul className="list-bare uni-choices__search-results">
          { universities.map(uni => (
            <li key={ uni } aria-role="button" onClick={ () => onChange(uni) } className='uni-choices__result'>{ uni }</li>
          )) }
        </ul>
      );
    }

  });


  var UniSelections = React.createClass({

    render: function() {
      var onChange = this.props.onChange;
      var universities = this.props.universities;
      return (
        <div className="uni-choices__selections">
          <h2>My university choices</h2>
          { universities.map(uni => (
            uni === "I don't know" ? null : <button key={ uni } className="uni-choices__selection" value={ uni } onClick={ () => this.props.onChange(uni) }>{ uni }</button>
          )) }
        </div>
      );
    }

  });


  var UniSelector = React.createClass({

    getInitialState: function() {
      return {universities: [], searchTerm: '', focused: false};
    },

    componentWillMount: function() {
      this.setState({universities: this.props.dpc.universities});
    },

    add: function(name) {
      if (this.state.universities.indexOf(name) === -1) {
        var universities = [].concat(this.state.universities);
        universities.push(name);
        universities.sort();
        this.setState({universities: universities, searchTerm: ''});
      }
    },

    remove: function(name) {
      var universities = [].concat(this.state.universities).filter(uni => uni !== name);
      this.setState({universities: universities});
    },

    onDontKnow: function() {
      var self = this;
      this.setState({universities: ["I don't know"]}, function() {
        self.handleNext();
      });
    },

    handleNext: function() {
      var universities = [].concat(this.state.universities);
      if (universities.length) {
        $.ajax({
          method: 'POST', url: '/profile/uni_choices', data: {university: universities},
          success: () => {
            this.props.onChangeState({universities: universities}).then(() => this.props.onNext());
          }, error: console.error
        });
      }
      else {
        this.props.onNext();
      }
    },

    handleFocus: function() {
      this.setState({focused: true});
    },

    handleBlur: function() {
      window.setTimeout(() => {
        this.setState({focused: false});
      }, 150);
    },

    render: function() {
      var searchTerm = this.state.searchTerm;
      var universities = this.state.universities;
      return (
        <UniChoicesPage title={ this.props.title }
          render={ () => (
            <div className="uni-choices">
              <UniList profile={ this.props.profile } checks searchTerm={ searchTerm } selections={ universities } onChange={ uni => this.add(uni) } onRemove={ uni => this.remove(uni) } />
            </div>
          ) }
          renderFooter={ () => (
            <Pager onBack={ () => this.props.onBack() } onNext={ () => this.handleNext() } nextLabel={ this.props.nextLabel || 'Continue'}>
            </Pager>
          )}
          />
      )
    }
  });


  var YearSelector = React.createClass({

    getInitialState: function() {
      return {year: null};
    },

    componentDidMount: function() {
      this.setState({year: this.props.dpc.year});

      $.ajax({
        method: 'POST', url: '/profile/uni_courses_prompted',
        success: () => {}, error: console.error
      });
    },

    handleClick: function(value) {
      $.ajax({
        method: 'POST', url: '/profile/uni_year', data: {year: value},
        success: () => this.props.onChangeState({year: value}).then(() => this.next(value)),
        error: console.error
      });
    },

    next: function(value) {
      if (value === 'not_going') {
        this.props.onClose()
      }
      else {
        this.props.onNext()
      }
    },

    render: function() {
      var buttons = [];
      var dt = new Date;
      var addYear = dt.getMonth() > 7 ? 1 : 0;
      for (var y = dt.getFullYear() + addYear, j = 0; j < 4; ++j, ++y) {
        buttons.push([y, 'Yes (start in ' + y + ')']);
      }
      buttons.push(['other', 'Yes (other year)']);
      buttons.push(['not_going', 'No']);
      buttons.push(['unsure', 'Unsure']);
      return (
        <UniChoicesPage title={ this.props.title }
          render={ () => (
            <div className="uni-years">
            { buttons.map(b => {
              var value = b[0], label = b[1];
              return <button key={ value } className={ "uni-years__year" + (value == this.state.year ? ' uni-years__year--selected' : '') } value={ value } onClick={ () => this.handleClick(value) }>{ label }</button>
            }) }
            </div>
          ) }
          />
      )
    }

  });

  var UnsureUni = React.createClass({

    getInitialState: function() {
      return {saving: false, course_update_emails: false, mobile_no: ''};
    },

    componentDidMount: function() {
      this.setState({course_update_emails: !!this.props.dpc.course_update_emails, mobile_no: this.props.dpc.mobile_no || ''});
    },

    handleNext: function(value) {
      this.setState({saving: true});
      var state = {mobile_no: this.state.mobile_no, course_update_emails: this.state.course_update_emails};
      $.ajax({
        method: 'POST', url: '/profile/dpc_contact_prefs', data: state,
        success: () => {
          this.setState({saving: false});
          this.props.onChangeState(state).then(() => this.props.onNext());
        }, error: (err) => {
          this.setState({saving: false});
          console.error(err);
        }
      });
    },

    render: function() {
      return (
      <UniChoicesPage title={ this.props.title }
        render={ () => (
          <div className="uni-unsure">
            <p>More than 70,000 students found their university place through Clearing last year. If you're not sure what to expect from results day, sign up for our Clearing alerts. We'll update you with helpful advice and new course spaces that match your interests.</p>
            <div className="clearing-contact-prefs__mobile">
              <input type='checkbox' checked={!!this.state.course_update_emails} id='course_update_emails' onChange={ e => this.setState({course_update_emails: !!e.target.checked}) } /><label htmlFor='course_update_emails'>Yes, please email me about these opportunities during the results period</label>
            </div>

            <p className="clearing-contact-prefs__text-desc">To also get these opportunities by text, enter your mobile number</p>
            <div className="clearing-contact-prefs__mobile">
              <input aria-label="Your mobile number" type="text" value={ this.state.mobile_no } placeholder="Your phone number" onChange={ e => this.setState({mobile_no: e.target.value}) } />
            </div>

            <p>We are always careful with your data and we won’t be sharing your details. The <a href="/pages/privacy" target="_blank">site privacy notice</a> has full details of how your personal data will be used.</p>
          </div>
        ) }
          renderFooter={ () => (
            <Pager onBack={ () => this.props.onBack() } onNext={ () => this.handleNext() } nextLabel="Submit">
            </Pager>
          )}
        />
      )
    }

  });


  var Pager = function(props) {
    return (
      <div className="uni-choices-page__pager">
        { props.onBack ? <div className="uni-choices-pager__back"><button className="btn--text" onClick={ props.onBack }>« Back</button></div> : null }
        { props.children ? <div className="uni-choices-page__inner">{props.children}</div> : null }
        { props.onNext ? <div className="uni-choices-pager__next"><button className="btn btn--primary" disabled={ props.saving } onClick={ props.saving ? () => {} : props.onNext }>{ props.saving ? 'Saving…' : (props.nextLabel || "Continue") }</button></div> : null }
      </div>
    );
  }

  function setPageParameter(page) {
    if (typeof history !== 'undefined' && history.pushState) {
      var url = new URL(window.location)
      url.searchParams.set('page', page)
      history.pushState({}, '', url)
    }
  }

  var UniChoices = React.createClass({

    getInitialState: function() {
      var m = location.search.match(/page=(.+)/)
      return {pages: this.props.pages, page: findPage(this.props.pages, m ? m[1] : '').id, universities: [], courses: []};
    },

    componentWillMount: function() {
      var self = this
      $(window).on('popstate', function() {
        var m = location.search.match(/^page=(.+)/)
        var page = findPage(self.state.pages, m ? m[1] : '').id
        self.setState({page: page})
      })
      var nextState = this.props.dpc
      nextState.pages = filterPages(this.props.pages, nextState)
      this.setState(nextState);
    },

    componentWillUnmount: function() {
      $(window).off('popstate')
    },

    onChangeState: function(value) {
      value.pages = filterPages(this.props.pages, Object.assign({}, this.state, value))
      return new Promise(resolve => {
        this.setState(value, resolve);
      })
    },

    handleClose: function() {
      this.props.close();
    },

    handleBack: function() {
      var i = findPageIndex(this.state.pages, this.state.page)
      var page = this.state.pages[Math.max(0, i - 1)].id
      setPageParameter(page)
      this.setState({page: page})
    },

    handleNext: function() {
      var i = findPageIndex(this.state.pages, this.state.page)
      var page = this.state.pages[Math.min(this.state.pages.length - 1, i + 1)].id
      setPageParameter(page)
      this.setState({page: page})
    },

    render: function() {
      var props = findPage(this.state.pages, this.state.page)
      var Component = props.component;
      return (
        <Component dpc={ this.state }
          profile={ this.props.profile }
          title={ props.title }
          nextLabel={ props.nextLabel }
          onChangeState={ this.onChangeState }
          onClose={ () => this.handleClose() }
          onBack={ () => this.handleBack() }
          onNext={ () => this.handleNext() }
          />
      )
    }

  });


  var UniChoicesPage = React.createClass({

    render: function() {
      var title = this.props.title;
      var render = this.props.render;
      var renderFooter = this.props.renderFooter;
      return (
        <div className={ "uni-choices-page" + (this.props.id ? " uni-choices-page--" + this.props.id : '') }>
          <img className="uni-choices-page__logo" src="/assets/524ba46-181430/application/styles/images/logo-600x140.png" width="250" alt="" />
          { title ? <h1>{ title }</h1> : null }
          <div className="uni-choices-page__content">
            { render(this.props) }
          </div>
          { renderFooter ? renderFooter(this.props) : null }
        </div>
      )
    }

  });


  var ModalRoot = React.createClass({

    getInitialState: function() {
      return {visible: true};
    },

    componentDidMount: function() {
      var $window = $(window);
      $window.on('resize', () => {
        if ($window.outerWidth() < 729) {
          this.setState({
            visible: this.state.visible,
            needsRotate: false
          });
        }
      }).triggerHandler('resize');
    },

    show: function() {
      this.setVisible(true);
    },

    hide: function() {
      if (this.props.nextUrl) {
        window.location = this.props.nextUrl;
      }
      else {
        this.setVisible(false);
      }
    },

    dismiss: function() {
      $.ajax({
        method: 'POST', url: '/profile/uni_courses_dismiss',
        success: () => {}, error: console.error
      });
      this.hide();
    },

    setVisible: function(visible) {
      var state = this.state;
      if (visible !== state.visible) {

        state.visible = visible;

        if (visible) {
          state.pageScroll = $('body').scrollTop();
          setTimeout(() => {
            $('html,body').animate({ scrollTop: 0 }, 100);
            setTimeout(() => {
              $('html,body').css({'overflow':'hidden', 'height': '100%', 'width':'100%'});
              $(window).trigger('resize');
              this.setState(state);
            }, 90);
          }, 300);
        }
        else {
          this.setState(state);
          setTimeout(() => {
            $('html,body')
              .delay('slow')
              .css({'overflow':'', 'height': '', 'width':''})
              .scrollTop(state.pageScroll)
            ;
          }, 300);
        }

      }
    },

    render: function() {
      return (
        <div className="fullscreen__wrapper fullscreen__wrapper--fixed" style={{display: this.state.visible?'':'none'}}>
          <div className="modal-fullscreen">
            <div className="fullscreen__content-container fullscreen__content-container--dpc" key="fullscreen__content--container">
              {this.props.render({close: this.hide})}
              <span className="btn btn--fullscreen--close" onClick={() => this.dismiss()}><span className="hide">Close</span></span>
            </div>
            <React.addons.CSSTransitionGroup transitionName="react-transition" transitionEnterTimeout={260} transitionLeaveTimeout={260}>
            {(() => {
              if (this.state.needsRotate) {
                return (
                  <span className="rotate" key="fullscreen__content__rotate"><strong>rotate</strong> to view</span>
                )
              }
            })()}
            </React.addons.CSSTransitionGroup>
          </div>
          <div className="modal-backdrop fade in "></div>
        </div>
      );
    }

  });

  var uniPages = [
    {
      id: 'year',
      title: "Are you interested in applying to university?",
      component: YearSelector,
    },
    {
      id: 'contact',
      title: "Unsure about your uni place?",
      component: UnsureUni,
      filter: (state) => state.year === 'unsure' || parseInt('' + state.year, 10) === 2021,
    },
    {
      id: 'subjects',
      title: "What subjects might you like to study at uni?",
      component: CourseSelector,
    },
    {
      id: 'uni',
      title: "Which universities are you applying to or interested in?",
      component: UniSelector,
    },
    {
      id: 'thanks',
      title: "Thank you for submitting",
      component: Thanks,
    }
  ];

  var clearingPages = [
    {
      id: 'clearing-intro',
      title: "Don't miss out this year!",
      component: ClearingSteps,
    },
    {
      id: 'courses',
      title: "What subjects might you like to study at uni?",
      component: CourseSelector,
    },
    {
      id: 'uni',
      title: "Which universities are you applying to or interested in?",
      component: UniSelector,
      nextLabel: "Finish",
    },
    {
      id: 'thanks',
      title: "Thank you for submitting",
      component: Thanks,
    }
  ];

  function filterPages(pages, state) {
    return pages.filter(x => !x.filter || x.filter(state));
  }

  function findPageIndex(pages, id) {
    return Math.max(Math.min(pages.findIndex(x => x.id === id), pages.length - 1), 0)
  }

  function findPage(pages, id) {
    return pages[findPageIndex(pages, id)]
  }

})(jQuery);
