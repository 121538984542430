
(function($) {

	function updateSidebar($resource) {
		var $sidebar = $resource.closest('.main-content').find('.resource-sidebar').first();
		if ($sidebar.length) {
			var diff = $sidebar.outerHeight() - $resource.outerHeight();
			if (diff > 0) {
				var $items = $sidebar.find('.resources-mlt .item');
				var nremove = Math.ceil(diff / $items.first().outerHeight());
				if ($items.length - nremove < 5) nremove = Math.min($items.size(), $items.size() - 5);
				if (nremove > 0) $items.slice( - nremove).hide();
			}
		}
	};

	var trackedView = false;

	function installEventTracking($resource) {
		var meta = $('#page').data('resource-meta');
		if (meta && meta.kind && meta.kind.toLowerCase() !== 'flashcards' && meta.kind.toLowerCase() !== 'revision cards') {
			var event_category = meta.kind;
			if ($resource.hasClass('is-owner')) {
				event_category = 'Own ' + event_category;
			}
			if ( ! trackedView) {
				// in case 2 resource views are on page for an a/b test
				ccore.gaq(['_trackEvent', event_category, 'View', document.location.href.replace(/^https?:\/\/[^/]+/, ''), true]);
				trackedView = true;
			}
			$resource.find('.documentcontainer a').click(function() {
				ccore.gaq(['_trackEvent', event_category, 'Download', document.location.href.replace(/^https?:\/\/[^/]+/, '')]);
			});
		}
	}

	$.cf.installer('.resource-view', updateSidebar);
	$.cf.installer('.resource-view', installEventTracking);

	$(document).ready(function() {
		if (typeof googletag !== 'undefined') {
			googletag.cmd.push(function() {
				googletag.pubads().addEventListener('slotRenderEnded', function(e) {
					setTimeout(function() {
						updateSidebar($('.resource-view'));
					}, 1);
				});
			});
		}
	});

})(jQuery);

