// vim: et sw=2 ts=2

(function($) {

  function extractParamFromUri(uri, paramName) {
    if (!uri) {
      return;
    }
    var regex = new RegExp('[\\?&#]' + paramName + '=([^&#]*)');
    var params = regex.exec(uri);
    if (params != null) {
      return unescape(params[1]);
    }
    return;
  }

  function trackTwitter(intent_event) {
    if (intent_event) {
      var opt_pagePath;
      if (intent_event.target && intent_event.target.nodeName == 'A') {
        opt_pagePath = extractParamFromUri(intent_event.target.href, 'url');
      }
      else if (intent_event.target && intent_event.target.nodeName == 'IFRAME') {
        opt_pagePath = extractParamFromUri(intent_event.target.src, 'url');
      }
      ccore.gaq(['_trackSocial', 'twitter', 'tweet', opt_pagePath]);
      ccore.stats.action('social.twitter.tweet', opt_pagePath);
    }
  }

  // Twitter SDK

  window.twttr = (function (d,s,id) {var t, js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return; js=d.createElement(s); js.id=id;js.src="//platform.twitter.com/widgets.js"; fjs.parentNode.insertBefore(js, fjs);return window.twttr || (t = { _e: [], ready: function(f){ t._e.push(f) } });}(document, "script", "twitter-wjs"));

  twttr.ready(function (twttr) {
    twttr.events.bind('tweet', trackTwitter);

    if ($.cf && $.cf.installer) {
      $.cf.installer(function() {
        twttr.widgets.load()
      });
    }
  });


  // Facebook SDK is loaded in head_script

  var fbTrack = {
    share: function(targetUrl) {
      ccore.gaq(['_trackSocial', 'facebook', 'share', targetUrl]);
      ccore.stats.action('social.fb.share', targetUrl);
    },
    like: function(targetUrl) {
      ccore.gaq(['_trackSocial', 'facebook', 'like', targetUrl]);
      ccore.stats.action('social.fb.like', targetUrl);
    },
    unlike: function(targetUrl) {
      ccore.gaq(['_trackSocial', 'facebook', 'unlike', targetUrl]);
      ccore.stats.action('social.fb.unlike', targetUrl);
    }
  };

  window.grFB = {
    init: function(id) {
      FB.init({appId: id, xfbml: true, version: 'v2.2'});
      if (window.grFBID) delete window.grFBID;

      FB.Event.subscribe('edge.create', fbTrack.like);
      FB.Event.subscribe('edge.remove', fbTrack.unlike);
      FB.Event.subscribe('message.send', fbTrack.share);
    },
    share: function(targetUrl) {
      FB.ui({
        method: 'share',
        href: targetUrl
      },
      function(r) {
        if (r && !r.error_code) {
          fbTrack.share(targetUrl);
        }
      });
    },
  };

  if (window.grFBID && typeof FB !== 'undefined') {
    window.grFB.init(window.grFBID);
  }


  window.grGPlus = {
    share: function(targetUrl) {
      var w = window.open(
        "https://plus.google.com/share?url=" + escape(targetUrl), '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
      );
      ccore.gaq(['_trackSocial', 'google', 'share', targetUrl]);
      ccore.stats.action('social.gplus.share', targetUrl);
    }
  };

})(jQuery);

