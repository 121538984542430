
(function($) {

	var has_board = function(sl) {
		return !sl.subject.uri.match(/^fun$/) && !sl.level.uri.match(/^fun$/);
	};

	// revise-studypicker is a dropdown of the user's studies, selecting
	// any of which enables/populates the topic picker.
	//
	$.cf.installer('.revise-studypicker', function() {
		var $study_field = $(this);
		var $form = $study_field.closest('form');
		var $topic_field = $form.find('.fm-property-topic');
		if ($topic_field.length) {
			var topicloader = new TopicPicker($topic_field, $form);
			var update_topic = function() {
				var sl = {subject:{uri:''}, level:{uri:''}};
				var x = $(this).val().split(',');
				if (x.length) {
					sl.level.uri = x[0];
					if (x.length > 1) {
						sl.subject.uri = x[1];
					}
				}
				topicloader.update(sl.level.uri, sl.subject.uri);
			};
			var $studies = $study_field.find('select');
			$studies.on('change', update_topic);
		}
	});

	// reviseExamAvailability is a combination of level+subject+board+topic.
	// the subjects list is auto-populated based on current level.
	// topic list is auto-populated based on current level + subject.
	//
	$.cf.installer('.reviseExamAvailability', function() {
		var $sl_field = $(this);
		var $form = $sl_field.closest('form');
		var $board_field, $other_board_field;
		$board_field = $sl_field.find('.fm-property-board');
		$other_board_field = $sl_field.find('.fm-property-other_board, .fm-property-other-board');
		if ($board_field.length === 0) {
			$board_field = $form.find('.fm-property-board');
			$other_board_field = $form.find('.fm-property-other_board, .fm-property-other-board');
		}
		var $topic_field = $form.find('.fm-property-topic');

		var sl = new ExamAvailability($sl_field, $form);

		$sl_field.data('subjects-sl-picker', sl);

		if ($board_field.length) {
			if ($other_board_field.length) {
				var boardpicker = new BoardPicker($board_field, $other_board_field);
				$board_field.data('subjects-board-picker', boardpicker);
				var update_board = function(e, sl) {
					if (has_board(sl)) {
						boardpicker.show();
					}
					else {
						boardpicker.hide();
					}
				};
				$(sl).on('change', update_board);
				update_board(null, sl.subject_level());
			}
		}

		if ($topic_field.length) {
			var topicloader = new TopicPicker($topic_field, $form);
			$topic_field.data('subjects-topic-picker', topicloader);
			var update_topic = function(e, sl, noupdate) {
				if (!noupdate) topicloader.update(sl.level.uri, sl.subject.uri);
			};
			$(sl).on('change', update_topic);
			update_topic(null, sl.subject_level(), true);
		}
	});

	var BoardPicker = function($root, $other_root) {

		$root.find('select').on('change', function(e) {
			if ($(this).val() === 'other') {
				$other_root.show();
			}
			else {
				$other_root.hide();
				$other_root.find('input[type="text"]').val('');
			}
		});

		this.val = function(v) {
			var $select = $root.find('select');
			if (typeof v === 'undefined') {
				var val = $select.val();
				if (val === 'other') {
					return $other_root.find('input[type="text"]').val();
				}
				return val;
			}
			var $opt = $select.find('option[value="' + v + '"]');
			if ($opt.length) {
				$select.val(v);
			}
			else {
				$select.val('other');
				$other_root.find('input[type="text"]').val(v);
			}
			return this;
		};

		this.show = function() {
			$root.show();
			if ($root.find('select').val() === 'other') {
				$other_root.show();
			}
			else {
				$other_root.hide();
			}
		};

		this.hide = function() {
			$root.hide();
			$root.find('select').val('none');
			$other_root.hide();
			$other_root.find('input[type="text"]').val('');
		};

		this.show();

	};

	var TopicPicker = function($root, $form) {

		$root.parents('.fm-field').first().addClass('fm-field-topic');

		var $empty_field = $root.find('.topic-field').first().clone();
		$empty_field.find('select option[value!=""]').remove();
		$empty_field.find('input[type="hidden"]').val('');

		var label_text = $root.find('label').first().text();
		var field_name = $empty_field.find('input[type="hidden"]').attr('name');

		var empty_label = $root.data('empty-label');
		if (!empty_label) empty_label = $empty_field.find('select option').text();
		if (!empty_label) empty_label = 'Select a topic';

		var $add_topic_button;
		if ($root.hasClass('fm-multiple')) {
			$add_topic_button = $('<span class="add_footer"><span class="add_property">Add another topic</span></span>');
			$root.append($add_topic_button);
		}
		else {
			$add_topic_button = $();
		}

		$root.on('change keyup', '.topic-freetext, select', function(e) {
			var $target = $(e.target);
			var $field = $target.closest('.topic-field');
			var $hidden = $field.find('input[type="hidden"]');
			if ($target.is('select') && $target.val() === ':other') {
				show_other($field);
				set_value($field, '');
			}
			else {
				if (!$target.is('.topic-other')) {
					hide_other($field);
				}
				set_value($field, $target.val());
			}
		});

		$add_topic_button.on('click', function(e) {
			var $last = $root.find('.topic-field').last();
			var $field = $last.clone();
			$last.after($field);
			hide_other($field);
			$field.find('select, input').val('');
			$field.cf_install();
		});

		var set_value = function($field, v) {
			if (v.length) $add_topic_button.show();
			else $add_topic_button.hide();
			if ($field) {
				if ($field.find('input[type="hidden"]').val() != v) {
					$field.find('input[type="hidden"]').val(v);
					$field.trigger('topic_change', [v]);
				}
			}
		};

		var show_other = function($field, value) {
			if (0 === $field.find('.topic-other').length) {
				$field.append(
					$("<div>", {'class': 'fm-options topic-other-wrapper'})
					.append(
						$("<label>", {'text': 'Please identify the topic for this resource *:', 'class': 'topic-other-wrapper'})
						.append($("<input>", {'type': 'text', 'class': 'textbox topic-freetext topic-other'}))
					)
				);
				if (typeof value === 'undefined') {
					$field.find('.topic-freetext').focus();
				}
				else {
					$field.find('.topic-freetext').val(value);
				}
			}
		};

		var hide_other = function($field) {
			$field.find('.topic-other-wrapper').remove();
		};

		var show_freetext = function() {
			$root.find('.topic-field').remove();
			set_value(null, '');
			$root.find('label').first().text('Please identify the topic for this resource *:');
			var $field = $("<div>", {'class': 'topic-field topic-freetext-field'})
			.append($("<input>", {'type': 'text', 'class': 'textbox topic-freetext'}))
			.append($("<input>", {'type': 'hidden', 'name': field_name}))
			;
			if ($add_topic_button.length) $add_topic_button.before($field);
			else $root.append($field);
			$field.find('.topic-freetext').focus();
			$topicClone = $root.find('.topic-field').clone();
		};

		var show_options = function(options) {
			$root.find('label').first().text(label_text);
			$root.find('.topic-field').remove();
			var $new_field = $empty_field.clone();
			var $select = $new_field.find('select');
			$select.removeAttr('disabled');
			$select.find('option[value!=""]').remove();
			$.each(options, function() {
				$select.append($("<option>", {'text': this, 'value': this}));
			});
			$select.append($("<option>", {'text': 'Other', 'value': ':other'}));
			if (0 === $root.find('.topic-field').length) {
				$root.find('.fm-options').append($new_field);
				set_value($new_field, '');
			}
			$topicClone = $root.find('.topic-field').clone();
			$root.cf_install();
		};

		this.clear = function() {
			var $field = $empty_field.clone();
			$root.find('.topic-field').remove();
			if ($add_topic_button.length) $add_topic_button.before($field);
			else $root.append($field);
			$root.cf_install();
		};

		this.update = function(level_id, subject_id) {
			$root.show();
			if (level_id && subject_id) {
				$.ajax({
					type: 'GET',
					url: $root.data('api-url').replace(/{level_id}/, level_id).replace(/{subject_id}/, subject_id),
					success: function(response) {
						if (response.status === 'ok') {
							if (response.results.length) {
								show_options(response.results);
							}
							else {
								show_freetext();
							}
						}
					},
					error: function(response) {
						// console.log('Error', response);
						show_freetext();
					}
				});
			}
			else {
				this.clear();
			}
		};

		this.hide = function() {
			this.clear();
			$root.hide();
		};

		if (0 !== $root.find('.topic-field input[type="hidden"][value=""]').length) {
			$add_topic_button.hide();
		}

		// show other textbox for topics currently set to "other"
		$root.find('.topic-field select option:selected[value=":other"]').each(function() {
			var $field = $(this).closest('.topic-field');
			show_other($field, $field.find('input[type="hidden"]').val());
		});

		this.topics = function() {
			var topics = [];
			var $inputs = $root.find('.topic-field input[type="hidden"]');
			$inputs.each(function() {
				topics.push(this.value);
			});
			return topics;
		};
	};

	var ExamAvailability = function($root, $form) {
		var self = this;

		var input, level_select, subject_select, $board_select;
		var subjects = [], levels = {};

		var s_empty_label = $root.data('s-empty-label');
		var l_empty_label = $root.data('l-empty-label');

		if (!l_empty_label) l_empty_label = 'Select a level of study';
		if (!s_empty_label) s_empty_label = 'Select a subject';

		function init() {
			var select = $root.find('select').first()
				, div, name, id, value
			;

			if (typeof window.examAvailability === 'object') {

				div = $root.find('.fm-options').first();
				input = div.find('[type=hidden]').first();
				name = input.attr('name').replace(/-value$/, '');
				value = input.val();

				levels = window.examAvailability.levels;
				subjects = window.examAvailability.subjects;

			}
			else {

				if ( ! select.length) return;
				div = select.parent();
				name = select.attr('name');
				id = select.attr('id') || name;

				input = $('#' + id.replace(/\//, '-') + '-value');
				value = input.val();

				// parse nested subject+level select into objects
				//
				select.find('optgroup').each(function() {
					var subject = {'uri':'', 'title':this.label};
					$(this).find('option').each(function() {
						var option = $(this);
						var uri = option.val().split(',');
						subject.uri = uri[1];
						if (typeof levels[uri[0]] === 'undefined') {
							levels[uri[0]] = {'uri':uri[0], 'title':option.text(), 'subjects':[]};
						}
						levels[uri[0]].subjects.push(subjects.length);
					});
					subjects.push(subject);
				});

			}

			if (value) {
				value = value.split(',');
			}

			$board_select = $form.find('select[name="' + name.replace('subject_level', 'board') + '"]');

			// replace nested select with a hidden input
			select.remove();
			input = $('<input>', {
				type:'hidden',
				name:name,
				id:name + '_value',
				value:value
			});
			div.append(input);

			// find element to insert new subject + level selects before
			var before = $root.find('p.error').first();
			if (before.length == 0) before = input;

			// create the level picker
			level_select = $("<select class='select levels'>");
			before.before(level_select);

			var option = $("<option>", {value:''});
			option.text(l_empty_label);
			level_select.append(option);

			var default_level = $('#' + (name + '/default-level').replace(/\//g, '-'));
			var preferred;
			if (default_level) {
				preferred = default_level.val();
				default_level.attr('name', '');
			}

			var _id_count = 1;

			$.each($.map(levels, function(v, k) {return v}).sort(sortByTitle), function() {
				var level = this;
				id = 'opt-ex-' + (_id_count++);
				if ( ! wantsURI(level.uri)) return;
				var option = $("<option>", {value:level.uri, text:level.title});
				level_select.append(option);
				if (((value && value[0] === level.uri) || (!(value && value[0]) && preferred === level.uri))) {
					level_select.val(level.uri);
				}
			});

			// create the subject picker
			subject_select = $("<select class='select subjects'>");

			if ($root.parent().is('.studies-editor__cell')) {
				$root.find('label').first().text('Level');
				var $field = $("<div>", {'class': 'fm-field fm-property-subject'});
				subject_select.attr('id', 'fm-property-subject-' + (_id_count++));
				$field.append($("<label>", {'for': subject_select.attr('id'), 'text': 'Subject', 'class': 'fm-field-name'}));
				$field.append($("<div>", {'class':'fm-options reviseExamAvailability'}).append(subject_select));
				$root.parent().next().append($field);
			}
			else {
				before.before(subject_select);
			}

			var option = $("<option value=''>");
			option.text(s_empty_label);
			subject_select.append(option);

			if (value && value[0] || preferred) {
				updateSubjects();
			}
			if (value && value[1]) {
				subject_select.val(value[1]);
			}

			if (!subject_select.val()) {
				if ($root.hasClass('slb-no-require-level')) {
					showAllSubjects();
				}
				else {
					subject_select.attr('disabled', true);
				}
			}

			level_select.on('change', onChange);
			subject_select.on('change', onChange);

			updateHidden();

			$root.find('input.tm').remove();

			updateOthers();

			raiseEvent();
		}

		function wantsURI(uri) {
			if (uri === 'all' && ! $root.data('show-all')) {
				return false;
			}
			if (uri === 'fun' && ! $root.data('show-fun')) {
				return false;
			}
			if (uri === 'other' && ! $root.data('show-other')) {
				return false;
			}
			return true;
		}

		function showAllSubjects(value) {
			var select = subject_select.get(0);
			var i = 0;
			$.each(subjects, function(k, subject) {
				if ( ! wantsURI(subject.uri)) return;
				var option = document.createElement('option');
				option.setAttribute('value', subject.uri);
				option.textContent = subject.title;
				select.appendChild(option);
				if (value && value === option.subject.uri) {
					select.selectedIndex = i;
				}
				++i;
			});
		}

		function getSLB() {
			var value = {
				'subject': {'uri': null, 'title': null},
				'level': {'uri': null, 'title': null}
			};
			if (value.subject.uri = subject_select.val()) {
				value.subject.title = subject_select.find('option').eq(subject_select.prop('selectedIndex')).text();
			}
			if (value.level.uri = level_select.val()) {
				value.level.title = level_select.find('option').eq(level_select.prop('selectedIndex')).text();
			}
			if ($board_select.length) {
				var val = $board_select.val();
				value.board = {
					uri: val,
					title: $board_select.find('option[value="' + val + '"]').text()
				};
			}
			return value;
		}

		this.subject_level = getSLB;

		function raiseEvent() {
			var value = getSLB();
			$(document).trigger('slb_update', [value]);
			$(self).trigger('change', [value]);
			$root.trigger('slchange', [value]);
			$('.page-body select, .modal-body select').trigger('render');
		}

		function sortByTitle(a, b) {
			return a.title < b.title ? -1 : (a.title > b.title ? 1 : 0);
		}

		function onChange(e) {
			var target = $(e.target);
			if (target.is('select')) {
				if (target.hasClass('levels')) {
					updateSubjects();
				}
				updateHidden();
				updateOthers();

				raiseEvent();
			}
		}

		function updateSubjects() {
			var value = subject_select.val();
			subject_select.children().remove();
			if (/MSIE [0-9]/.test(navigator.userAgent)) {
				var $parent = subject_select.parent();
				subject_select.remove();
				if ($root.parent().is('.studies-editor__cell')) {
					$parent.append(subject_select);
				}
				else {
					level_select.after(subject_select);
				}
				subject_select.on('change', onChange);
			}
			var level = levels[level_select.val()];
			var option = $('<option>', {value:'', text:'Select a subject'});
			subject_select.append(option);
			if (typeof level === 'object') {
				var available_subjects = [];
				$.each(level.subjects, function(i, v) {
					available_subjects.push(subjects[v]);
				});
				if (available_subjects.length) {
					subject_select.attr('disabled', false);
					available_subjects.sort(sortByTitle);
					available_subjects.forEach(function(subject) {
						if ( ! wantsURI(subject.uri)) return;
						var option = $('<option>', {value:subject.uri, text:subject.title});
						subject_select.append(option);
						if (value && value === option.val()) {
							subject_select.val(value);
						}
					});
				}
				else if (!$root.hasClass('slb-no-require-level')) {
					subject_select.attr('disabled', true);
				}
				else {
					showAllSubjects(value);
				}
			}
			else if (!$root.hasClass('slb-no-require-level')) {
				subject_select.attr('disabled', true);
			}
			else {
				showAllSubjects(value);
			}
		}

		function updateHidden() {
			var value = getSLB()
			if ($root.hasClass('slb-no-require-level')) {
				input.val(value.level.uri + ',' + value.subject.uri);
			}
			else {
				if (value.subject.uri && value.level.uri) input.val(value.level.uri + ',' + value.subject.uri);
				else input.val('');
			}
		}

		function updateOthers() {
			var level;

			level = $form.find('div.fm-property-other-level').first();
			if (level.length == 0) level = $form.find('div.fm-property-other_level').first();

			if (level.length) {
				if (level_select.val() === 'other') {
					level.css('display', 'block');
				}
				else {
					level.css('display', 'none');
				}
			}
		}

		init();
	}

})(jQuery);

