// vim: et sw=2 ts=2
(function($) {

   function reWrite(){
      if ($(window).width() < 549){
         $('.homepage__search__searchbox').attr('placeholder', 'Try the \'Cold War\' or \'DNA\'');
      }else{
         $('.homepage__search__searchbox').attr('placeholder', 'Try the \'Cold War\', \'DNA\' or \'Algebra\'...');
      }
   }
  $(document).ready(function(){
      reWrite();
  });
  $(window).on('resize', function(){
      reWrite();
  });
})(jQuery);
