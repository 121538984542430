// vim: et sw=2 ts=2
(function($) {

  var state_loaded = false
    , replace_id = 0
    , initial_html = null
  ;

  $(window).on('popstate', function(e) {
    if ( ! state_loaded) return;
    if (history.state) {
      $('#content').html(history.state.html).cf_install();
    }
    else {
      $('#content').html(initial_html).cf_install();
    }
  });

  $(document).ready(function() {

    // Add the search result html strings previously retrieved using ajax to the DOM. The strings are stored as array elements in the history state object.
    // Currently only applied to the search results page, tested as working on qdb resources pages and can also be used there if needed.
    if ($('.search-page').length) {
      if (history['state']) {
        if (history.state['ajaxContent']) {

          // Use the existing ajax-replace functionality to sequentially append all ajaxContent array elements (ie html strings retrieved using ajax) to the DOM.
          for (var i = 0, len = history.state.ajaxContent.length; i < len; i++) {
            var $target = $('.search-page').find('[data-ajax-replace-target]');
            if ($target.length) {
              $target.replaceWith(history.state.ajaxContent[i]);
            } else {
              console.error('ajax-replace-target not found when appending from history state object.');
            }
          }

        } else {
          console.error('History state object already exists but has no "ajaxContent" property.');
        }
      } else if (history.replaceState) {
        // Create the history state object if it doesn't exist already.
        history.replaceState({ajaxContent: []}, '');
      }
    }

    initial_html = $('#content').html();
  });

  $.cf.installer('.ajax-replace', function($el) {
    var $target = $($el.data('ajax-replace-target'));
    if ($target.length) {

      $el.on('click', function(e) {
        var href = $el.attr('href');
        href += (href.indexOf('?') === -1 ? '?' : '&') + 'ajax';
        e.preventDefault();
        $el.addClass('ajax-replace--loading');
        $.ajax({
          url: href,
          success: function(response) {

            // Append the newly arrived ajax search results to the history state object array.
            if ($('.search-page').length && history.replaceState) {
              history.replaceState({ajaxContent: history.state.ajaxContent.concat([response.html])}, '');
            }

            var $new = $(response.html);
            $target.replaceWith($new);

            if ($el.data('ajax-replace-update-history')) {
              state_loaded = true;
              if (history.pushState) {
                history.pushState({'html': $('#content').html()}, null, $el.attr('href'));
              }
            }

            $new.cf_install();
          },
          error: function() {
            document.location.href = $el.attr('href');
          }
        });
        return false;
      });

    }
    else {
      console.error('ajax-replace-target not found');
    }
  });

})(window.jQuery);

