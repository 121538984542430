// vim: et sw=2 ts=2
(function($, G) {

  $.cf.installer('.collapsable', function($root) {

    // body is the element that gets collapsed using max-height property.
    var $body = $root.find('.collapsable__body, body').first();

    // the element on which clicks toggle open/closed state
    var $toggle = $root.find('.collapsable__toggle').first();

    // full height of the body element, default is set in the CSS
    // and is set high so we don't inadvertantly crop content. this
    // js will detect the real height to improve the animation speed.
    var full_height = 0;

    var setup = function() {
      // undo changes in case setup is being called again (window resize/orientationchange)
      $toggle.off('click');
      $body.css('max-height', '');

      // convert per-breakpoint classes
      var breakpoints = G.cfui.mediaQueryBreakpoints();
      for (var name in breakpoints) {
        if (G.cfui.mediaQuery(name)) {
          var only = $root.attr('class').match(/collapsable--only-([a-z0-9_-]+)/);
          if ( ! only || only[1] === name) {
            $root.addClass('collapsable');
          }
          if ($root.hasClass('collapsable--in--' + name)) {
            $root.removeClass('collapsable--in--' + name).addClass('collapsable--in');
          }
        }
        else if ($root.hasClass('collapsable--only-' + name)) {
          $root.removeClass('collapsable');
          return;
        }
      }

      // if the element is open let's update the max-height on the body. this
      // improves the transition animation speed rather than using the high
      // default value. only do this if there aren't more collapsables inside it,
      // and if the height won't change dynamically.
      // The 'collapse-variable-height' class is added to descendant elements whose height can change dynamically, eg child elements added by javascript.
      // The outerHeight() of these elements on load won't necessarily reflect their future height if their contents can be changed dynamically, so shouldn't be used.
      if ($root.hasClass('collapsable--in') && (0 === $root.find('.collapsable').length) && (0 === $root.find('.collapsable-variable-height').length)) {
        full_height = $body.outerHeight();
        $body.css('max-height', full_height + 'px');
      }

      $toggle.on({

        'click': function(e) {
          if ($root.hasClass('collapsable--in')) {
            $root.removeClass('collapsable--in');
            $body.css('max-height', '0px');
          }
          else {
            $root.addClass('collapsable--in');
            if (full_height) {
              // if we know the full height we can set it as a style attribute to
              // improve the performance of the transition animation. it otherwise
              // goes up to the large max-height specified in the CSS.
              $body.css('max-height', full_height + 'px');
            }
            else {
              $body.css('max-height', '');
              // if we don't know it yet (started closed) let's grab the full height when transition completes.
              $body.bind("webkitTransitionEnd.collapse_transition_done oTransitionEnd.collapse_transition_done otransitionend.collapse_transition_done transitionend.collapse_transition_done msTransitionEnd.collapse_transition_done", function(){
                if ($root.hasClass('collapsable--in') && (0 === $root.find('.collapsable').length) && (0 === $root.find('.collapsable-variable-height').length)) {
                  full_height = $body.outerHeight();
                  if (full_height) {
                    $body.css('max-height', full_height + 'px');
                  }
                }
                $(this).off('.collapse_transition_done');
              });
            }
          }
        }

      });

    };

    $(window).on('resize orientationchange', setup);

    setup();

  });

})(jQuery, window);
