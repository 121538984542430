
window.onerror = function(m, u, l, c, e) {
	if (u.match(/^https?:\/\/(static.)?getrevising.co.uk/) && ! m.match(/SecurityError: DOM Exception 18/)) {
		c = c || '';
		e = e ? (e.stack ? e.stack.toString() : e.toString()) : '';
		(new Image).src = "/jserror?m=" + escape(m) + "&u=" + escape(u) + "&l=" + escape(l) + "&c=" + escape(c) + "&e=" + escape(e) + "&p=" + escape(document.location.href);
	}
};

(function($) {

	if (!$) throw new Error('jQuery not loaded');

	var site_title = 'Get Revising';

	var sub_price = "£6.99";

	var path_prefix = /^\/g\//.test(document.location.pathname) ? '/g/' : '/';

	function userType() {
		var $page = $('#page');
		if ($page.hasClass('is-subscriber')) return 'subscriber';
		if ($page.hasClass('is-member')) return 'member';
		return 'guest';
	}

	function userTypeName() {
		var labels = {
			'guest': 'Guest', 'member': 'Member', 'subscriber': 'Subscriber'
		};
		return labels[userType()];
	}

	var ab_timer = null;

	var timedABTemplate = function(ab, timeout) {
		if (typeof timeout === 'undefined') timeout = 1000;
		ab_timer = setTimeout(function() {
			grABTemplate(ab);
		}, timeout);
	};

	window.grABTemplate = function(ab) {
		if (ab_timer !== null) {
			clearTimeout(ab_timer);
			ab_timer = null;
		}
		timedABTemplate = function() {};
		var other = /-a$/.test(ab) ? ab.replace(/-a$/, '-b') : ab.replace(/-b$/, '-a');
		$('#page').removeClass('ab-' + other).addClass('ab-' + ab).trigger('gr_abchange', [ab]);
		setTimeout(function() {
			$('#page').removeClass('ab-' + other + '-2').addClass('ab-' + ab + '-2');
		}, 1);
	};

	window.isABTemplate = function(ab) {
		return $('#page').hasClass('ab-' + ab);
	};

	/**
	 * Track an event in Optimizely
	 *
	 * @param   string  eventName
	 * @return  void
	 */
	window.trackOptimizely = function(eventName) {
		eventName = eventName.replace('{userTypeName}', userTypeName());
		if (!eventName || eventName.length == 0) return;
	};

	/**
	 * Track an event in Dynamic Yield
	 *
	 * @param   string  eventName
	 * @param   object  properties
	 * @return  void
	 */
	window.trackDY = function(eventName, properties) {
		properties = properties || {}
		eventName = eventName.replace('{userTypeName}', userTypeName());
		if (!eventName || eventName.length == 0) return;
		var is_dev = $(document.documentElement).is('.env-stage, .env-dev, .env-local');
		if (is_dev) {
			console.log('DY: ' + JSON.stringify({name: eventName, properties: properties}));
		}
	};

	$.lang = $.lang || {};

	$.lang.number_format = function(n, decPlaces, decSeparator, thouSeparator) {
		var decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
			decSeparator = typeof decSeparator === 'undefined' ? "." : decSeparator,
			thouSeparator = typeof thouSeparator === 'undefined' ? "," : thouSeparator,
			sign = n < 0 ? "-" : "",
			i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces), 10) + "",
			j = (j = i.length) > 3 ? j % 3 : 0;
		return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
	};

	function trackSubscribeSource(cta_label, cta_id, cta_loc) {
		cta_loc = cta_loc || '';
		var data = {}
			, $page = $('#page')
		;
		data.cta_label = cta_label;
		data.cta_id = cta_id;
		if (cta_loc) data.cta_loc = cta_loc;
		data.url = document.location.href;
		data.page_type = $page.data('page_type');
		if (data.page_type === 'resource') {
			data.page_subtype = $page.data('page_subtype');
			var resource_meta = $page.data('resource-meta');
			if (resource_meta) {
				data.level = resource_meta.level_id;
				data.subject = resource_meta.subject_id;
				data.kind = resource_meta.kind_id;
			}
		}
		else {
			var page_meta = $page.data('page_meta');
			if (page_meta) $.each(page_meta, function(k, v) {
				data[k] = v;
			});
		}
		$.cookies.set('GRSS', data);
	}

	function wantsPopovers() {
		return ! cfui.mediaQuery('lap-and-under');
	}

	function handleRegisterLink(e) {
		var href = $(e.target).closest('a').attr('href');
		if (href) {
			href += (href.indexOf('?') === -1 ? '?' : '&') + 'back=' + escape($('meta[property="og:title"]').attr('content'));
			document.location.href = href;
			return true;
		}
	}


	$.cf.access
	.validator('subscriber', function() {
		return 1 === $('#page.is-subscriber').size();
	})
	.validator('user', function() {
		return 1 === $('#page.is-member').length;
	})
	.validator('owner', function(el) {
		el = $(el);
		return el.closest('.resource-view').hasClass('is-owner')
		|| (el.hasClass('dia-root') && el.data('dia-owner') == '1');
	})
	.validator('moderator', function(el) {
		return 1 === $('#page.is-moderator').length;
	})
	.validator('admin', function(el) {
		return 1 === $('#page.is-admin').length;
	})
	.validator('print_service', function(el) {
		return /print/.test(window.location.search)
	})
	.validator('freebie', function(el) {
		return $(el).closest('.resource-view').hasClass('is-full-view')
		|| $(el).closest('.grCards').hasClass('is-full-view')
		|| $(el).hasClass('is-free-preview');
	})
	.rules(function() {
		return $('#page').data('access-ui');
	})
	.action('', function(src) {

		if ( ! wantsPopovers()) {
			var href = path_prefix + 'register';
			href += '?' + 'back=' + escape($('meta[property="og:title"]').attr('content'));
			document.location.href = href;
			return;
		}

		var $src = $(src);
		var title = $src.data('access-title') || 'Want to read more?';
		var body = $src.data('access-body');
		var resource_count = $('#page').data('resource-count');
		if ( ! resource_count) resource_count = '425,000';
		if (body) {
			dialog = {
				title: title,
				body: body
			};
		}
		else {
			var dialog = $src.data('access-dialog');
			var dialogs = $('#page').data('access-dialogs');
			if (dialog && typeof dialogs[dialog] !== 'undefined' && typeof dialogs[dialog] !== 'undefined') {
				dialog = dialogs[dialog];
			}
			else {
				dialog = {
					title: title
				};
				dialog.body = "Our library has " + resource_count + " learning resources just like this one.";
			}
		}

		var member = $('#page.is-member').length;
		if(member) {
		  var button_label = "subscribe now";
		} else {
		  var button_label = "sign up now";
		}

		var accessDialog = function(dialog, on_join) {
			var url;

			if (/\/revision-notes\//.test(document.location.href)) {
				window.trackOptimizely('OptimizelyNotesPopUp');
			}

			url = path_prefix + 'register';
			var $header = $("<h3>", {'text': dialog.title})
			.add($("<p>", {'text': dialog.body, 'class': 'subtitle'}))
			.add($("<span>", {'class': 'close'}))
			;
			var resource_count = $('#page').data('resource-count');
			if ( ! resource_count) resource_count = '425,000';
			var $body = $($("<ul>", {'class': 'usp-list'})
										.append($("<li>", {'text': 'Access over ' + resource_count + ' learning resources'}))
										.append($("<li>", {'text': 'Track your progress with testing technology'}))
										.append($("<li>", {'text': 'Join study groups to learn with other students'}))
									 ).add($("<div>", {'class': 'signup-block'})
									 	.append($("<p><span class='cta'>Premium <strong>only " + sub_price + "</strong> </span><button class='btn btn-hook'>" + button_label + "</button><a href=\"/auth/login?referer=" + encodeURIComponent(location.pathname + location.search) + "\" class='signup-block__login'>Log in</a><span class='notnow close'>Not now, thanks</span></p>"))
										// .append($("<div class='signup-block__inner'><button class='btn btn-hook btn--cta'>" + button_label + "</button><a href=\"/auth/login?referer=" + encodeURIComponent(location.pathname + location.search) + "\" class='signup-block__login'>Log in</a> <span class='notnow close'>Not now, thanks</span></div>"))
									 )
			;
			var $page = $('#page');
			var resource_stats = $page.data('similar-resource-stats')
				, user_stats = $page.data('similar-member-stats')
				, resource_meta = $page.data('resource-meta')
			;
			if (resource_meta && (resource_stats || user_stats)) {
				var $access = $("<div>", {'class': 'could-access'})
				.append($("<h4>", {'text': 'You could immediately access'}))
					, $list = $("<ul>")
				;
				if (resource_stats && resource_meta.subject) {
					$list.append($("<li>", {'text': $.lang.number_format(resource_stats.subject, 0) + ' ' + resource_meta.subject + ' resources'}));
					$list.append($("<li>", {'text': $.lang.number_format(resource_stats.kind, 0) + ' ' + resource_meta.subject + ' ' + resource_meta.kind_plural.toLowerCase()}));
				}
				if (user_stats && resource_meta.subject) {
					$list.append($("<li>", {'text': $.lang.number_format(user_stats, 0) + ' registered students revising ' + resource_meta.subject}));
				}
				if ($list.children().length) {
					$access.append($list);
					$body = $body.add($access);
				}
			}
			var mb = $.MessageBox({
				'class': 'modal-subscribe-dialog',
				'header': $header,
				'body': $body
			});
			mb.on('hidden', function() {$(this).remove()});
			mb.find('.close').on('click', function() {
				mb.modal('hide');
			});
			$body.find('.btn-hook').on('click', function() {
				on_join();
				document.location.href = url;
			});
			mb.modal();
		};

		ccore.gaq(['_trackEvent', 'Promotion', 'Join Now Show']);

		accessDialog(dialog, function() {
			trackSubscribeSource(button_label, 'paywall-popup-primary', dialog.feature);
			var resource_meta = $('#page').data('resource-meta')
			window.trackOptimizely('paywall-pop-up-primary-cta-click');
			ccore.gaq(['_trackEvent', 'cta', 'paywall-pop-up-primary-cta-click', resource_meta ? resource_meta.kind : '']);
		});

	})
	;

	$(document).ready(function() {

		$('html').removeClass('nojs');
		$('html').addClass('hasjs');
		$(document.body).addClass('jsi');

		$.cookie('gr_client', {hoursToLive: 365 * 24, secure: document.location.protocol === 'https:'}).set('js', true);

		site_title = document.title.replace(/^(.+?)( -.*)?$/, '$1');

		if (/\/revision-notes\//.test(document.location.href)) {
			$('#div-gpt-ad-1334060026094-0').on('click', function() {
				window.trackOptimizely('OptimizelyNotesMPU');
			});
		}

		/**
		 * Set up homepage video
		 */
		if ($('#page.home').length) {
			videojs("video-player").ready(function(){
				var myPlayer = this;
				var played = false;
				myPlayer.on('play', function() {
					if (!played) {
						played = true;
						ccore.gaq(['_trackEvent', 'Video', 'Play', 'GR-testimonial']);
					}
					else {
						ccore.gaq(['_trackEvent', 'Video', 'Resume', 'GR-testimonial']);
					}
				});
				myPlayer.on('pause', function() {
					ccore.gaq(['_trackEvent', 'Video', 'Pause', 'GR-testimonial']);
				});
				myPlayer.on('ended', function() {
					played = false;
					ccore.gaq(['_trackEvent', 'Video', 'End', 'GR-testimonial']);
				});
			});
		}

		$('.resource-view--video .video-js').each(function() {
			videojs(this).ready(function(){
				var myPlayer = this;
				var played = false;
				var videoName = document.location.pathname;
				myPlayer.on('play', function() {
					if (!played) {
						played = true;
						ccore.gaq(['_trackEvent', 'Video', 'Play', videoName]);
					}
					else {
						ccore.gaq(['_trackEvent', 'Video', 'Resume', videoName]);
					}
				});
				myPlayer.on('pause', function() {
					ccore.gaq(['_trackEvent', 'Video', 'Pause', videoName]);
				});
				myPlayer.on('ended', function() {
					played = false;
					ccore.gaq(['_trackEvent', 'Video', 'End', videoName]);
				});
			});
		});


		/**
		 * Event tracking
		 */

		// track what caused a subscription (sets a cookie checked after subscription complete)
		$(document.body).on('click cfpopoverload', '[data-cta-id]', function(e) {
			var $el = $(e.target).closest('a');
			if ($el.length) trackSubscribeSource($el.text(), $el.data('cta-id'), $el.data('cta-loc'));
		});

		// anything clicked on with data-ga-event-category
		$('[data-ga-event-category]').on('click', function(e) {
			var $target = $(this);
			ccore.gaq(['_trackEvent', $target.data('ga-event-category'), $target.data('ga-event-action')]);
		});

		// anything clicked on with data-optimizely-event
		$(document.body).on('click', '[data-optimizely-event], [data-optimizely-event] span', function(e) {
			var $target = $(e.target).closest('[data-optimizely-event]');
			window.trackOptimizely($target.data('optimizely-event'));
		});

		// anything clicked on with data-gr-event
		$('[data-gr-event]').on('click', function(e) {
			var $target = $(this)
				, url = $target.data('gr-url')
				, data = {
						event: $target.data('gr-event')
					, subject: $target.data('gr-subject')
					, data: $target.data('gr-data')
				}
				, rv
				, complete = function() {}
			;
			if ($target.is('a')) {
				rv = false;
				e.preventDefault();
				complete = function() {document.location.href = $target.attr('href')};
			}
			$.ajax({
				type: 'POST',
				url: url,
				data: data,
				complete: complete
			});
			return rv;
		});

		// track PDF clicks as social shares
		$(document.body).on('click', '.btn-download-pdf, .a6 a, .a7 a', function(e) {
			var $target = $(e.target).closest('a');
			if ( ! /(\/subscribe|#$)/.test($target.attr('href'))) {
				var targetUrl = document.location.pathname + document.location.search;
				ccore.gaq(['_trackSocial', 'gr', 'pdf', targetUrl]);
				ccore.stats.action('social.gr.pdf', targetUrl);
			}
		});

		var properties;
		// track completed registrations and subscriptions
		if ((properties = $('#page').data('registration_complete'))) {
			window.trackOptimizely('registered-free');
			window.trackDY('registered', properties);
		}

		// track completed logins
		else if ((properties = $('#page').data('login_complete'))) {
			window.trackDY('login', properties);
		}

		// track created resources
		if ((properties = $('#page').data('resource_created'))) {
			window.trackDY('resource_created', properties);
		}

		// track created planners
		if ((properties = $('#page').data('planner_created'))) {
			window.trackDY('planner_created', properties);
		}

		$(document.body).on('click', '.resources-mlt', function(e) {
			var $target = $(e.target);
			if ($target.is('a')) {
				var data = {'url': $target.attr('href')};
				var resource_meta = $('#page').data('resource-meta');
				if (resource_meta) {
					data.level = resource_meta.level_id;
					data.subject = resource_meta.subject_id;
				}
				window.trackDY('related_resource_clicked', data);
			}
		});


		/**
		 * Access popovers triggered when a link with .require-access is clicked,
		 * similar popovers for .subscribe-popover and .promo-popover links.
		 */
		$(document.body).on('click', '.require-access', function(e) {
			if (wantsPopovers()) {
				if (!$.cf.access.can($(this).data('access'), $(this))) {
					$.cf.access.resolve(this);
					return false;
				}
			}
			else if (handleRegisterLink(e)) {
				return false;
			}
		});

		$(document.body).on('click', '.subscribe-popover', function(e) {
			if (wantsPopovers()) {
				$.cf.access.run_action('', $(this));
				return false;
			}
			else if (handleRegisterLink(e)) {
				return false;
			}
		});

		$(document.body).on('click', '.promo-popover, .cfui-popover', function(e) {
			if (wantsPopovers()) {
				var popover = new $.cfui.Popover();
				popover
				.title('Loading…')
				.load($(this).attr('href'))
				.show()
				;
				return false;
			}
			else if (handleRegisterLink(e)) {
				return false;
			}
		});

		/**
		 * Show modal specified in page data if exists
		 */
		var onload_message = $('#page').data('gr-modal');
		if (onload_message) {
			$.MessageBox(onload_message).modal();
		}

		// Select all and Continue button on combo select forms
		//
		var el;

		if ((el = $('#select-all')).length) {
			var checks = $('ul.picker input[type=checkbox]');
			if (checks.length) {
				el.text('Select all and Continue').on('click', function(e) {
					checks.attr('checked', 'checked');
				});
			}
		}

		// Make external links open in a new window
		//
		$('a').filter(function() {
			return this.href.substring(0, 4) === 'http' && (window.location.hostname !== this.hostname || this.getAttribute('rel') === 'external')
		}).attr('target', '_blank');

		// Make document links open in a new window
		//
		$('.documentcontainer a, .resource-view .download a, .btn-download-pdf, .a6 a, .a7 a').attr('target', '_blank');


		// Check that cookies are enabled
		//
		if (/\/auth\/login|\/register/.test(document.location.href)) {
			if (typeof navigator.cookieEnabled !== 'undefined' && !navigator.cookieEnabled) {
				$('.page-content').prepend('<div class="alert alert-block alert-error">It looks like you have Cookies disabled in your browser.<br />You will need to enable them if you wish to register or log in.</div>');
			}
		}


		// GA events for clicks in the "Related resources" sidebar area
		//
		$('.browse-by').on('click', function(e) {
			var $target = $(e.target);
			if ($target.is('a') && _gaq) {
				if ($target.is('.a-s')) {
					ccore.gaq(['_trackEvent', "Related Resources", 'Click - Subject', $target.innerText]);
				}
				else if ($target.is('.a-sl')) {
					ccore.gaq(['_trackEvent', "Related Resources", 'Click - Level+Subject', $target.innerText]);
				}
				else if ($target.is('.a-ls')) {
					ccore.gaq(['_trackEvent', "Related Resources", 'Click - Subject+Level', $target.innerText]);
				}
				else if ($target.parents('ul.tags').length) {
					ccore.gaq(['_trackEvent', "Related Resources", 'Click - Topic', $target.innerText]);
				}
				else if ($target.parents('.listing').length) {
					ccore.gaq(['_trackEvent', "Related Resources", 'Click - Similar Resources', $target.innerText]);
				}
				else if ($target.parents('.more-results').length) {
					ccore.gaq(['_trackEvent', "Related Resources", 'Click - More Resources', $target.innerText]);
				}
			}
		});


		// install the jquery-placeholder plugin
		if ($.fn.placeholder) {
			$.cf.installer(function() {
				$('input, textarea').placeholder();
			});
		}


		// install the bootstrap popover plugin
		if ($.fn.popover) {
			$.cf.installer('.popover-toggle', function($el) {
				$el.popover({html:true, placement:'top', container:'body'})
				.on('click', function(e) {
					$('.popover-toggle').not(this).popover('hide');
					e.preventDefault();
					return false;
				});
			});
			$.cf.installer('.popover-auto', function($el) {
				$el.popover({html:true, placement:'top', container:'body'})
				.popover('show')
			});
			$('body').on('click', function() {
				$('.popover-toggle, .popover-auto').popover('hide');
			});
		}

		if ($.cfui && $.cfui.Popover) {
			$.cf.installer('.cf-popover-link', function($el) {
				$el.on('click', function(e) {
					var href = this.href;
					if (-1 == href.lastIndexOf('?')) {
						href += '?popover=1';
					}
					else {
						href += '&popover=1';
					}
					$(
						(new $.cfui.Popover())
						.title('Loading…')
						.load(href)
						.show()
					)
					return false;
				});
			});
		}

		// install the bootstrap tooltip plugin
		if ($.fn.tooltip) {
			$('.with-tooltip').tooltip({
				container:'body'
			});
		}


		// select tab given by URL fragment
		if (document.location.hash) {
			var h = document.location.hash;
			$('.nav-tabs a[data-toggle="tab"]').each(function() {
				if (this.href.substring(this.href.length - h.length) === h) {
					$(this).tab('show');
				}
			});
		}


		$('[data-toggle="toggle"]').each(function() {
			var $this = $(this);
			if ($this.is('input[type="radio"]')) {
				var toggle = function() {
					if ($this.is(':checked')) $($this.data('target')).show(100);
					else $($this.data('target')).hide(100);
				};
				$(document).on('click', 'input[type="radio"][name="' + $this.attr('name') + '"]', toggle);
				toggle();
			}
		});

		$(document.body).on('click', 'a.post-link', function(e) {
			e.preventDefault();
			var $form = $("<form>", {
				action:this.href,
				method:'post',
				css: {
					visibility:'hidden',
					position:'absolute',
					top:'-9999em',
					left:'-9999em'
				}
			});
			$form.appendTo($(document.body)).submit();
			return false;
		});

		$('form[data-redirect]').on('submit', function() {
			var url = $(this).data('redirect');
			setTimeout(function() {
				document.location.href = url;
			}, 500);
		});

		$('.resources-list[data-kinds]').each(function() {
			var kinds = $(this).data('kinds');
			var $current = null;
			$(this).find('.item').each(function() {
				var $item = $(this);
				var m = $item.attr('class').match(/resource-kind-([a-z_-]+)/);
				if (m) {
					$("<div>", {title: kinds[m[1]], 'class': 'icon-type'})
					.appendTo($item)
					.tooltip({
						'placement': 'bottom',
						'container': 'body'
					});
				}
			});
		});

	});




	/**
		* Collapsing/Expanding module.
		*/
	$.cf.installer('.cms-widget.collapsable', function($root) {
		var $body = $root.find('.body');
		$body.addClass('collapse');
		if ( ! $body.hasClass('in')) $root.addClass('collapsable-closed');
		else $root.removeClass('collapsable-closed');

		$root.find('.title').on('click', function(e) {
			$body.collapse('toggle');
			if ( ! $body.hasClass('in')) $root.addClass('collapsable-closed');
			else $root.removeClass('collapsable-closed');
			var $popover = $root.parents('.cf-popover').first();
			if ($popover.length) {
				$popover.data('cf-popover').resize(300);
			}
		});
	});


	/**
		* "Is Teacher" radios toggle Institution etc.
		*/
	$.cf.installer('#content-is-teacher-1-opt1', function($el) {
		var el = this;
		$el.parents('div').on('click', function(e) {
			if (el.checked) {
				$el.parents('form').addClass('is-teacher');
				$('#group-teacher').show();
				$('#group-student').hide();
			}
			else {
				$el.parents('form').removeClass('is-teacher');
				$('#group-teacher').hide();
				$('#group-student').show();

			}
		}).trigger('click');
	});


	/**
		* Collapsing/Expanding resource meta.
		*/
	$.cf.installer('.resource-view .resource-meta-collapsable', function() {
		var $this = $(this);
		var $description = $this.find('.resource-description');
		var $open = $("<span>", {'class':'collapse-details collapse-details-open', 'text':'Show all resource details'});
		var $close = $("<span>", {'class':'collapse-details collapse-details-close', 'text':'Hide resource details'});
		$this.after($open);
		$this.after($close);
		if ($('#page.is-member').length && $this.closest('.resource-view').is('.resource-type-resource.is-preview')) {
			$open.hide();
		}
		else {
			$close.hide();
			$description.collapse('toggle');
		}
		$description.on({
			'show': function() {
				$open.hide('fade', 100);
			},
			'shown': function() {
				$close.show('fade');
			},
			'hide': function() {
				$close.hide('fade', 100);
			},
			'hidden': function() {
				if (!$(this).hasClass('in')) $open.show('fade');
			}
		});
		$open.on('click', function() {
			$description.collapse('show');
			$this.addClass('in');
		});
		$close.on('click', function() {
			$description.collapse('hide');
			$this.removeClass('in');
		});

	});

	$.cf.installer('.fb-popup', function($link) {
		$link.on('click', function(e) {
			var $target = $(e.target).closest('a');
			window.open($target.attr("href"),$target.text(),"width=500, height=400");
			return false;
		});
	});

	/**
	 * p0350 - category page, make entire resource type box clickable
	 */
	if ( ! /\/(revision-tests|make)\//.test(document.location.href)) {
		$.cf.installer('.resources-types-list', function($types) {
			$types.find('.revision-tool a').on({
				'click': function(e) {
					trackOptimizely('OptimizelyResourceTypes');
					e.stopImmediatePropagation();
				}
			});
			$('.category-list a').on({
				'click': function(e) {
					trackOptimizely('OptimizelyFilter');
					e.stopImmediatePropagation();
				}
			});

			$types.find('.revision-tool').addClass('revision-tool--all-active').on({
				'click': function(e) {
					trackOptimizely('OptimizelyResourceTypes');
					document.location = $(this).find('a').attr('href');
					e.stopImmediatePropagation();
				}
			});
		});
	}

	if ($.receiveMessage) $.receiveMessage(function (e) {
		if (typeof e.data === 'string' && e.data.substring(0, 1) !== '{') return;
		var data;
		try {
			data = JSON.parse(e.data);
		}
		catch (e) {
			return;
		}
		switch(data['do']) {
			case 'resize':
				$(data['target']).show().css({
				'height': data['height'] + 'px'
			});
			break;
			case 'collapse':
				$(data['target']).hide();
			break;
		}
	}, document.location.protocol + '//' + document.location.hostname);

  $.cf.installer('.resource-editor .tab_panel_file', function($el) {
    $el.append($("<div>", {className: 'fm-description', text: 'Accepted file types: doc, docx, rtf, txt, pps, ppt, pptx, mp3, wav, wma, mp4, mov, wmp, gif, jpeg, png, tiff, pdf, xls, xlsx'}));
  });

  if (window.crossOriginIsolated) {
    // Post SharedArrayBuffer
    console.log("CrossOriginIsolation: true")
  }
  else {
    // Do something else
    console.log("CrossOriginIsolation: false")
  }

})(window.jQuery);

