
(function($, undefined) {

	var floatval = $.lang.floatval;
	var intval = $.lang.intval;


	var Rating = function($root) {
		var self = this;

		var api_url = '', access = '';

		var w = $root.outerWidth();
		var h = $root.outerHeight();

		var item_w = Math.floor(w / 5);

		var current_rating = '0.0';

		var can_rate = false;

		$root.css('position', 'relative').removeAttr('title');

		if ( ! 'ontouchstart' in document.documentElement) {
			var tips = [
				'Very poor, no useful content',
				'Poor, needs some work',
				'Useful, but could be improved',
				'Great, extremely useful',
				'Superb, an essential resource'
			];

			for (var i = 0; i < tips.length; ++i) {
				var $tooltip = $("<div>", {'class':'rating-tip-helper', 'title':tips[i]})
				.css({
					position: 'absolute',
					width: item_w + 'px',
					height: h + 'px',
					top: 0,
					left: (item_w * i) + 'px'
				})
				.appendTo($root)
				.tooltip({
					container:'body',
					placement:'bottom'
				})
				.hide()
				;
			}
		}

		function init() {
			api_url = $root.data('rating-url');
			access = $root.data('access');
			if (api_url || access) {
				self.allowRating(true);
			}
			current_rating = '' + floatval($root.find('span.rating').html());
			var items = intval($root.find('span.best').html());
			if (items > 0) item_w = w / items;
		}

		this.update = function() {
			self.updateRating();
		};

		this.allowRating = function(yesno) {
			if (yesno === false) {
				if (can_rate) {
					$root.off('.ratings');
					$root.css('cursor', 'auto');
					can_rate = false;
				}
			}
			else if (!can_rate) {
				$root.on({
					'mousemove.ratings': onMouseOver,
					'mouseout.ratings': onMouseOut,
					'click.ratings': onMouseClick
				});
				$root.css('cursor', 'pointer');
				can_rate = true;
			}
			if (can_rate) $root.find('.rating-tip-helper').show();
			else $root.find('.rating-tip-helper').hide();
		};

		this.updateRating = function() {
			var v = floatval($root.find('.rating__value').html()).toFixed(1, 1);
			if (isNaN(v)) v = (0.0).toFixed(1,1);
			self.setRating(v);
		};

		this.setRating = function(v) {
			if (current_rating.length == 1) current_rating += '.0';
			$root.removeClass('rating--' + current_rating.replace(/\./, '-'));
			v = '' + v;
			if (v.length == 1) v += '.0';
			$root.addClass('rating--' + v.replace(/\./, '-'));
			current_rating = v;
		};

		function onRatingComplete(r) {
			if (r.rating_total && r.rating_count) {
				self.allowRating(false);
				$root.find('span.rating').first().html((Math.round(2 * r.rating_total / r.rating_count) / 2).toFixed(1, 1));
				self.updateRating();
				if (r.html) {
					updateHtml(r.html);
				}
				else {
					$.ajax({
						'type': 'GET',
						'url': api_url.replace('/rate', '/render'),
						'success': function(response) {
							updateHtml(response);
						}
					});
				}
			}
			else if (r.html) {
				updateHtml(r.html);
			}

			var $x = $root;
			if (!$x.hasClass('.rating--enabled')) {
				$x = $root.find('.rating--enabled');
			}
			$x.trigger('ratingcomplete');
		}

		function updateHtml(html) {
			$('.rating-prefix, .rating-suffix').remove();
			$root.before($("<div class='rating-saved'>Rating saved</div>"));
			$root.remove();
		}

		function getRatingForMouseEvent(e) {
			var d = Math.round(((e.pageX + Math.floor(item_w / 2)) - $root.offset().left) / item_w);
			if (d < 1.0) d = 1.0;
			return d.toFixed(1,1);
		}

		function onMouseClick(e) {
			if (access && !$.cf.access.can(access, $root)) {
				$.cf.access.resolve($root);
				return false;
			}
			var v = intval(getRatingForMouseEvent(e));
			self.allowRating(false);
			self.setRating(v);
			$.ajax({
				'url': api_url,
				'type': 'POST',
				'data': {'rating': v},
				'success': onRatingComplete
			});
		}
		function onMouseOver(e) {
			self.setRating(getRatingForMouseEvent(e));
		}
		function onMouseOut(e) {
			self.updateRating();
		}


		init();
	};

	$(document).ready(function() {

		$('.rating--enabled').each(function() {

			new Rating($(this));

		});

	});

})(jQuery);

